.context{
  display: flex;
}

.context_inner {
  margin-right: 50px;
}

.sec_research img{
  width: 60px;
  margin: auto;

}

.eval_research {
  display: flex;
  flex-wrap: wrap;
}

.eval_research li {
  list-style: none;
  background-color: #6b69ea;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  text-align: center;
  margin: 10px;

}

table.table {
  border: 1px solid #334455;
  width: 100%;
}

.table tbody {
  width: 100%;
}

.inline_tr {
  display: inline-table;
  width: 100%;
}

.sec_research {
  flex-wrap: wrap;
}

.sec_research div {
  flex: 1;
  padding: 10px;
}

.grey {
  background-color: #F0F2F6;
  padding: 2rem 0px;
}

.three_col {
  column-count: 3;
}



.three_col ul {
  list-style-position: outside;
}

.ques {
  list-style-type: decimal-leading-zero;
  list-style-position: outside;
}

.ques div:nth-child(2) {
  margin: 0px 20px;
}


@media screen and (max-width: 774px) {
  .context {
    flex-direction: column;
  }

  .context_inner {
    margin: 0;
    margin-bottom: 40px;
  }
  
}

.container {
  width: 100%;
  margin: auto;
}

.context{
  display: flex;
}

.container h4 {
  font-weight: 100 !important;
  line-height: 38px;
  font-size: 20px;
}

.context_inner {
  margin-right: 50px;
}

.bg {
  width: 120%;
  position: absolute;
  bottom: 0px;
  right: -35%;
}
.vax_header {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.person {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  border: 5px solid #fff;
}

.interview {
  background: #dfe4ec;
  padding-bottom: 5rem;
}

.hmw {
  background: #f5f7fb;
  margin-top: 40px;
  padding: 100px 0px;
  align-items: center;
  display: flex;
  align-items: center;
  position: relative;
}

.phone_gif {
  border-radius: 20px;
  border: 4px solid #15395d;
  width: 250px;
  box-shadow: 4px 4px 30px #aeb4b9;
}

.web_gif {
  border-radius: 20px;
  border: 4px solid #15395d;
  width: 100%;
  box-shadow: 4px 4px 30px #aeb4b9;
}

.text {
  max-width: 600px;
  width: 100%;
}

.fin_text:nth-child(even) .text{
  margin-left: 40px;
}
.fin_text:nth-child(odd) .text {
  padding-right: 40px;
}

.text_section {
  margin: 7rem auto;
}

.final {
  background: #F0F2F6;
  padding: 100px 0px;
}

.section_margin {
  margin-top: 150px;
  margin-bottom: 150px;
}

.lofi {
  background: #e5e5e5;
  padding: 5rem 0;
  margin-top: 40px;
  align-items: center;
  display: flex;
  align-items: center;
  position: relative;
}

.flower {
  position: absolute;
  width: 200px;
  right: 0;
  bottom: -10px;
  z-index: -1;
}

.hmw h1 {
  widows: 100%;
  align-self: flex-start;
}

.sketches {
  display: flex;
  margin-top: 40px;
  align-items: center;
}
.persona {
  display: flex;
}

.quote {
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;
  font-family: 'Sofia Pro';
  color: #535b6d;
  font-style: italic;
}

.actively_working, .template_box{
  justify-content: space-between;
}
.final_stats h4 {
  color: #6b69ea;
  text-transform: uppercase;
  align-self: center;
}

.final_end h2 {
  text-align: center;
    text-transform: none;
    color: #687c94;
    font-size: 20px;
    font-weight: 300;
}

.final_stat_number {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.final_stat_num {
  position: relative;
}

.final_stats .flex_one:nth-child(2){
  margin-left: 60px;
}

.insights div {
  flex: 0 0 33%;
}

.landing_page {
  width: 100%;
  height: 500px;
  margin: 60px 0px;
}

.landing_page div {
  flex: 1;
}

.landing_page .image {
  background-image: url('/assets/images/landingpage.jpg');
  background-size: cover;
}

.landing.grey {
  padding: 0;
}

.landing_text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.landing_text_inner {
  width: 450px;
  padding-right: 50px;
}


.actively_working div {
  flex: 0 0 45%;
}

.template_box div {
  flex: 0 0 30%;
}

.actively_working div:nth-child(1) {
  margin-right: 20px;
  position: relative;
}

.actively_working div:nth-child(1)::after {
content: '\2192';
    color: #fff;
    display: flex;
    display: inline-flex;
    width: 40px;
    height: 40px;
    position: absolute;
    justify-content: center;
    border-radius: 100px;
    background-color: #6b69ea;
    align-items: baseline;
    padding-top: 6px;
    right: -60px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.final_stats {
  background-color: #f5f7fb;
  justify-content: center;
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 30px;

}
.final_stats_inner {
  justify-content: center;
}

.final_stat_num::after {
  content: '\2192';
    color: #fff;
    display: flex;
    display: inline-flex;
    width: 40px;
    height: 40px;
    position: absolute;
    justify-content: center;
    border-radius: 100px;
    background-color: #6b69ea;
    align-items: baseline;
    padding-top: 6px;
    right: -40px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.challenge {
  padding: 100px 20%;
  background-color: #F0F2F6;

}

.challenge h1 {
  color: #6b69ea;
  font-weight: 600;

}
.challenge h3 {

  text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 2px;

}

.cara_img {
  box-shadow: 0 0 30px #44556622;
}

.slider {
  margin-bottom: 260px;
}

.insight_ideas {
  padding: 20px;
  border-radius: 20px;
  position: relative;
  box-shadow: 6px 5px 20px 0px rgb(207, 215, 230)
}

.insight_ideas ul li{
  list-style-position: outside;
}

.insight_ideas ul{
  padding-left: 15px;
}

.insight_ideas:nth-child(2) {
margin: 0px 20px;
}

.insight p {
  color: rgb(41, 61, 90);
  font-weight: 400;
}

.title {
  font-family: 'Roboto Slab';
  color: #0074ff !important;
  margin-bottom: 10;
  font-weight: 900;font-size: 20px;
}

.number {
  font-family: 'Roboto Slab';
  font-size: 70px;
  color: #0074ff;
  position: absolute;
  top: 0;
  font-weight: 900;
}

.insight:nth-child(even) {
  margin: 0 20px;
}

.changes_title h4 {
  font-size: 1rem;
  color: #0074ff !important;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Roboto Slab';
  letter-spacing: 2px
}
.changes_title h3 {
  font-size: 1.5rem;
}

.dech {
  padding-left: 15px;
}

ul.dech li {
  list-style-position: outside;
}

.research {
  text-align: left;
  font-weight: 500;
  line-height: 1.4rem;
  width: 200px;
}

.flex_one {
  flex: 0 0 20%;
}

.flex_one:not(:nth-child(1)).flex_one:not(:last-child) {
  margin: 0 20px;
}

.stat {
  color: rgb(32, 42, 56);
  font-size: 60px;
  font-weight: 600;
  text-align: left;
}

.sus {
  background-color: #f5f7fb;
  padding: 5rem 0;
  display: flex;
  align-items: center;


}

.stats_list {
  margin-bottom: 100px;
}

.idea {
  flex-direction: column;
  flex: 0 0 46%;
  background-color: #0074ff33;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  display: flex;
  margin: 20px !important;
}

.insight_cont {
  flex-wrap: wrap;
}

.lofi_img {
  width: 70%;
  margin-right: -200;
  margin-left: 40;
}

.table {
  padding: 0 !important;
}

.overview_vid {
  box-shadow: rgba(68, 85, 102, 0.267) 4px 10px 90px;
  border-radius: 20px;
}

.table table {
  width: 100%;
}

.table td:not(:nth-child(1)) {
  width: 0%;
}
.table th:not(:nth-child(1)) {
  width: 0%;
}

.table td {
  padding: 5px;
  text-align: center;
}

.table tbody {
  display: table;
  padding-left: 10px;
}

.table .red, .table .green {
  text-align: center;
}

.table .red {
  background-color: rgb(254, 213, 213);
  font-size: 25px;
  line-height: 0px;
}

.final_results {
  margin-top: 40px;
}

.table .green {
  background-color: rgb(213, 254, 235);
  font-size: 16px;
  line-height: 0px;
}

.reflect {
  background-color: #f5f7fb;
  padding: 5rem 0px;
}

.reflect_text {
  margin-right: 40px;
  flex: 1;
}

.bar_graph {
  width: 45%;
  margin-top: 50px
}

.bar_graph_2 {
  width: 55%;
  margin-top: 50px
}

.concept_feedback {
  display: flex;
  flex-wrap: wrap;
}

.concept_feedback p {
  width: 100%;
  margin-right: 20px;
  max-width: 47%;
  margin-bottom: 30px;
  font-style: italic;
  font-family: "Sofia Pro";
  background-color: #f5f7fb;
  padding: 20px;
  border-radius: 20px;
}

.ob_images {
  margin: 3rem auto;
}

.observations {
  background-color: #f5f7fb;
}

.observe {
  width: 200px;
  height: auto;
  margin-right: 50px;
}

@media screen and (max-width: 1251px) {
  .sur_stats {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1111px) {
.concept_feedback p {
  max-width: 100%;
  margin-right: 0;

}
}

@media screen and (max-width: 1000px) {
  .text_section:nth-child(odd) div:nth-child(2) {
    margin-right: -100px;
  }

  .landing_page {
    flex-direction: column;
    height: 700px;
  }

  .landing_text_inner {
    width: 100%;
    padding: 20px;
  }

}

@media screen and (max-width: 870px) {
 .bar_graph, .bar_graph_2 {
   width: 100%;
 }

 .challenge {
   padding: 100px 10%;
 }
}
@media screen and (max-width: 800px) {
  .fin_text {
    flex-direction: column;
    align-items: baseline;
  }

  .fin_text:nth-child(even) .text {
    margin: 0;
  }

  .fin_text:nth-child(odd) .fin_text_first {
    order: 1;
  }

  .fin_text .text {
    padding-right: 0 !important;
    margin-top: 50px !important;
  }

  .fin_text:nth-child(odd) div:nth-child(2) {
    margin-right: 0px;
    
  }
}
@media screen and (max-width: 774px) {
  .final {
  }

  .hmw {
    z-index: 1;
  }

  .sketches {
    flex-direction: column;
  }

  .lofi {
    flex-direction: column;
  }

  .lofi_img {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
}

  .research {
    width: 150px;
  }

  .actively_working div:nth-child(1)::after {
    display: none;
  }
}

.progress {
  width: 100%;
  height: 5px;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 999999999999999999999999999999;
}

.progress_inner {
  background-color: #0074ff;
  height: 100%;
  /* transition: all 0.3s ease; */
}

@media screen and (max-width: 720px) {
  .final_stat_num::after {
    display: none
  }
}
@media screen and (max-width: 700px) {
  .reflect div {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .reflect_text {
    margin-right: 0;
  }

  .sec_research {
    flex-direction: column;
  }


 .challenge {
   padding: 50px 0%;
 }
  
}

@media screen and (max-width: 774px) {
  .web_gif {
    width: 100%;
  }
  .context {
    flex-direction: column;
  }

  .context_inner {
    margin: 0;
    margin-bottom: 40px;
  }

  .template_box {
    flex-direction: column;
  }

  .temp_hi {
    flex-direction: column;
  }
  
}
@media screen and (max-width: 600px) {
  .insight_cont {
    flex-direction: column;
  }

  .card {
    flex-direction: column;
    margin: 50px 0px;
    text-align: center;
  }

  .insight:nth-child(2) {
    margin: 20px 0px;
  }

  .ob_images {
    flex-direction: column;
  }

  .ob_images p {
    margin-top: 20px;
  }

  .observe {
    width: 100%;
  }
}


@media screen and (max-width: 578px) {
  .interview_basic {
    flex-direction: column;
    align-items: center;
  }

  .quote {
    margin: 0;
    padding: 0;
    margin-top: 50px;
  }
  .actively_working {
    flex-direction: column;
  }

  .final_stats_inner {
    flex-direction: column;
  }
}

.interview_card {
  display: flex;
  background-color: #F0F2F6;
  padding: 40px;
  border-radius: 10px;
  align-items: flex-start;
  margin: 20px 0;
}

.interview_card img {
  width: 100px;
  border-radius: 100px;
  margin-right: 40px;
}

.goals {
  display: flex;
}

.goal {
  background-color: #f0f2f6;
  padding: 20px;
  flex: 1;
  margin-top: 20px;
}

.goal:nth-child(2) {
  margin: 20px 20px 0;
}

@media screen and (max-width: 422px) {
  .sur_stats {
    justify-content: center;
  }
}