@value grey from './common/css/common.module.css';
@value blue from './common/css/common.module.css';

.navbar {
    background: transparent;
    position: absolute;
    width: 100%;
    z-index: 99999999;
    top: 0;
    font-weight: 600;
    font-family: 'basic-sans', sans-serif;
}

.container {
    max-width: 1200px;
    display: flex;
    width: 100%;
    margin: auto;
    padding: 1rem;

}

.navbar nav {
    padding: 0;
    font-weight: 400;
}

.navbar :global(.navbar-collapse) {
    text-align: left;
}

.brand {
    display: flex;
    align-items: center
}

.active {
    color: #384EDD !important;
    font-weight: 600 !important;
}

.brand_logo {
    width: 60px;
    margin-right: 10px;
}

.brand_title {
    font-family: 'sofia-pro';
    letter-spacing: 1px;
    font-weight: 700;
    color: #303A49;
}

:global(.navbar-toggle) {
    border: 0;
    border-color: transparent !important;
    cursor: pointer;
    color: blue;
}

:global(.navbar-toggler-icon) {
    color: blue

}

:global(.nav-link) {
    padding: 0 1.5rem !important;

}

:global(.navbar-light .navbar-nav .nav-link) {
    color: #303A49

}

:global(.navbar-toggler ) {
    background-color: #fff !important;
}



@media screen and (max-width: 1501px) {
    :global(.navbar-nav) {
        background-color: #fff !important;
        border-radius: 100px;
    }

}
@media screen and (max-width: 991px) {
    .container {
        display: block
    }

    .navbar :global(.navbar-brand) {
        float: left
    }
    .navbar :global(.navbar-toggler) {
        float: right
    }
    .navbar :global(.navbar-collapse) {
        clear: both;
        background-color: #fff;
        padding: 10px;
    }

}