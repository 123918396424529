@value grey from './common/css/common.module.css';
@value blue from './common/css/common.module.css';


.moving {
    position: fixed;
    top: 200px;
    left: 0px;
    list-style: none;
    color: #173355;
    flex-direction: column;
    z-index: 9999;
  }
  
  .moving a {
    color: #173355;
  }
  
  .moving a:hover {
    text-decoration: none;
    color: #0074ff;
  }
  
  .moving li {
    padding: 3px 0;
    font-size: 13px;
  }
  
  .moving ul {
    list-style: none;
  }
  
  .active a{
    border-left: 3px solid #3549e6;
    padding-left: 20px;
    color: #3549e6;
  }
  
@media screen and (max-width: 1251px) {
    .moving {
      top: 0;
      left: 0;
      background: #fff;
      width: 100%;
      overflow-x: scroll;
      white-space: nowrap;
      font-size: 16px;
      display: none;
      box-shadow: 0px 0px 20px #00000022;
    }
  
    .moving ul {
      padding-left: 10px;
      display: flex;
      margin: 0px;
    }
  
    .moving li {
      display: inline-block;
      padding: 15px;
      font-size: 16px;
    }
  
    .moving .active a {
      border-bottom: 2px solid #3549e6;
      border-left: 0;
      padding: 0;
    }
  
    .moving::-webkit-scrollbar {
      width: 0px;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
      display: none;
  }
    /* Optional: show position indicator in red */
    .moving::-webkit-scrollbar-thumb {
        background: transparent;
        width: 1;
    }
  }