.container {
  width: 100%;
  margin: auto;
}

.vax_header {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.persona {
  display: flex;
}

.quote {
  border-left: 3px solid #dfdfdf;
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;

  color: #999999;
  font-style: italic;
}

.bg {
  background-color: red;
  background-image:
  radial-gradient(
    circle at top right,
    #263841,
    #0d161c 70%
  );
}

.demos {
  display: flex;
}

.demos div {
  margin: 20px;
  text-align: center;
  flex: 1;
}

.demos h1 {
  margin: 15px 0px;
  font-size: 1.5em;
  color: #0074ff;
  font-weight: 700;
}

.demos p {
  color: #3b474e;
  text-align: center;
  font-weight: 100;
}
.title {
background-color: #0074ff;
    padding: 40px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    font-size: 2em;
    border-radius: 5px;
    margin-bottom: 50px;
}

@media screen and (max-width: 750px) {
  .chall {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 712px) {
  .demos {
    flex-direction: column;
  }
}
