.container {
  width: 100%;
  margin: auto;
}

.vax_header {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.persona {
  display: flex;
}

.quote {
  border-left: 3px solid #dfdfdf;
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;

  color: #999999;
  font-style: italic;
}

.insight {
  background-color: #0074ff33;
  padding: 10px;
  border-radius: 20px;
  position: relative;
}

.insight_ideas {
  padding: 20px;
  border-radius: 20px;
  position: relative;
  box-shadow: 6px 5px 20px 0px rgb(207, 215, 230)
}

.insight_ideas ul li{
  list-style-position: outside;
}

.insight_ideas ul{
  padding-left: 15px;
}

.insight_ideas:nth-child(2) {
margin: 0px 20px;
}

.insight p {
  padding-left: 50px;
  color: black
}

.title {
  font-family: 'Roboto Slab';
  color: #0074ff !important;
  margin-bottom: 10;
  font-weight: 900;font-size: 20px;
}

.number {
  font-family: 'Roboto Slab';
  font-size: 70px;
  color: #0074ff;
  position: absolute;
  top: 0;
  font-weight: 900;
}

.insight:nth-child(2) {
  margin: 0 20px;
}

.changes_title h4 {
  font-size: 1rem;
  color: #0074ff !important;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Roboto Slab';
  letter-spacing: 2px
}
.changes_title h6 {
  margin-top: 50px;
}

.dech {
  padding-left: 15px;
}

ul.dech li {
  list-style-position: outside;
}




@media screen and (max-width: 1100px) {
.changes {
  flex-direction: column;
}
}
@media screen and (max-width: 992px) {
  .insight_cont {
    flex-direction: column;
  }

  .insight:nth-child(2) {
    margin: 20px 0px;
  }

  .ideas {
    flex-direction: column;
  }

  .insight_ideas:nth-child(2) {
    margin: 30px 0px;
  }
}