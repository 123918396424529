.jumbotron {
  height: auto;
  width: 100%;
  position: relative;
  display: flex;
  background-color: #F0F2F6;
  z-index: -1;
}

.intro_header {
  font-size: 14px;
  color: #51596e;
  font-family: sofia-pro;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.subtext {
  font-size: 1.8rem;
  line-height: 3.8rem;
  font-family: 'sofia-pro';
  color:#55607a;
  font-weight: 300;
}

.hp1 {
  position: absolute;
  right: 0;
  top: -50px;
  z-index: -1;
  max-width: 250px

}

.hp2 {
  position: absolute;
  left: 0;
  top: 10%;
  z-index: -1;
  max-width: 270px;
}
.mouse {
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 30px;
}

.subtext b {
  color: #3a4fdd;
}

.bottom_shadow {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 10%;
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

}

.filter {
  margin: 0 20px;
}
.filter label {
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}

.text_change {
  color: #fff;
  background-color: #394EDD;
  line-height: 1px;
  padding: 0;
  display: inline;
  position: relative;
  padding: 2px;

}

.subtext {
  background-color: #fff !important;
}

.btns {
  font-size: 20px;
  margin: 50px 0px;
}

.btns a:nth-child(2):hover {
  background-color: #b4c0ce;
}

.filter label input {
  margin-right: 10px;
}

.typist {
  display: inline;
}
.info {
  z-index: 9999999;
  width: 1200px;
  margin: auto;
  padding: 0 30px;
  font-size: 3em;
  color: #303A49;
  margin: 200px auto;
  margin-bottom: 150px;
}

.top_logo {
  width: 100px;
  display: block;
  margin-right: 30px;
}

.text_bg {
  line-height: 1.5em;
  font-family: sofia-pro;
  color: #14234A;
  display: block;
  font-weight: 600;
  margin-bottom: 20px;
}

.btns a:nth-child(2) {
  margin-left: 20px;
  color: #303A49;
  background-color: #cad3dd
}

.project_container {
  max-width: 1200px;
  margin: 30px auto;
  margin-top: -80px;
  display: flex;
  flex-wrap: wrap !important;
}

.project, .project2 {
  background: #EBF3FF;
  border-radius: 10px;
  flex: 1;
  padding: 40px;
  position: relative;
  min-height: 400px;
}

.project_inner, .project_hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.project_hover {
  background: rgba(42, 50, 63, 0.8);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.project_hover h3 {
  color: white;
  text-align: center;
  font-family: 'Roboto Slab'
}

.project_hover div {
  width: 100%;
  text-align: center;
}

.project_hover p {
  color: white
}

.width {
  width: auto;
  height: inherit;

}

.height {
  width: inherit;
  height: auto;

}

.project:hover .project_hover, .project2:hover .project_hover {
  opacity: 1;
}

.project:nth-child(1) {
  flex: 0 0 60%;
  margin-right: 30px;
}

.project2:nth-child(2) {
  flex: 0 0 60%;
  margin-left: 30px;
}

.project img, .project2 img {
  width: 50%;
  margin: auto;
}

.content {
  margin: auto;
  max-width: 1200px;
}


.old_content {
  margin: auto;
  max-width: 1200px;
  margin-top:100px;
}

.old_content .project_container {
  margin-top: 20px;
}


.old_content h6 {
  background-color: #3749E6;
  padding: 5px;
  color: #fff;
  width: auto;
  display: inline-block;
  font-size: 14px;
  line-height: 1rem;
  margin-left: 20px;

}

.old_content h1 {
  font-size: 40px;
  text-transform: none;
  margin-left: 20px;
}

.filter {
  display: flex;
  align-items: center;
}

/* Customize the label (the container) */
.filter_container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  font-size: 16px;
}

/* Hide the browser's default checkbox */
.filter_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  height: 15px;
  width: 15px;
  border: 2px solid #0074ff;
  margin-right: 10px;
}

/* On mouse-over, add a grey background color */
.filter_container:hover input ~ .checkmark {
  background-color: #0074ff11;
}

/* When the checkbox is checked, add a blue background */
.filter_container input:checked ~ .checkmark {
  background-color: #ffffff;
  
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.filter_container input:checked ~ .checkmark:after {
  display: block;
}

.cont {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.other_cont {
  flex-wrap: wrap;
  display: flex
}

/* Style the checkmark/indicator */
.filter_container .checkmark:after {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 15px;
  content: '\2714';
  height: 5px;
  color: #0074ff;
  border: solid white;
  border-width: 0 3px 3px 0;
}

.vaxx img {
  width: 100%;
  height: auto;
}

:global(.Cursor--blinking) {
    opacity: 1;
    animation: blink 1s linear infinite;
}

.other_cont a {
  display: flex;
  flex: 0 0 30.5% !important;
}

@keyframes blink {
      0% { opacity:1; }
      50% { opacity:0; }
      100% { opacity:1; }
}

@media screen and (max-width: 1000px) {
  .text_out {
    display: block;
  }
  .text_change {
    margin: 0;
    height: 43px !important;
  }


}

@media screen and (max-width: 1280px) {

  .hp2 {
    top: -40%;
  }
}

@media screen and (max-width: 991px) {
  .project_container {
    flex-direction: column;
  }
  .project_container a {
    margin: 20px;
  }
  .moment img{
    height: auto !important;
    width: 100% !important;
  }
  .undraw img{
    height: 100% !important;
    width: auto !important;
  }
  .vaxx img {
    width: 80% !important;
    height: auto !important;
  }
  .design img{
    width: 80% !important;
  }
  .walden img{
    width: 60% !important;
  }
  .uoft img{
    width: 40% !important;
    height: auto !important;
  }

}

@media screen and (max-width: 620px) {
  .text_bg {
    font-size: 30px;
  }
  .subtext {
    font-size: 20px;
    line-height: 40px;
  }

  .uoft img{
    width: 70% !important;
    height: auto !important;
  }
}
@media screen and (max-width: 611px) {
  .filter {
    flex-direction: column;
  }

  .filter_container {
    padding: 0
  }

}
@media screen and (max-width: 562px) {
  .hp1 {
    max-width: 150px;
  }
  .hp2 {
    max-width: 170px;
    top: 15%;
  }
}
@media screen and (max-width: 466px) {
  .info {
    flex-direction: column;
    padding: 20px;
  }
  .text_bg {
    font-size: 30px;
    border: 0;
  }

  .top_logo {
    margin: auto;
  }
}