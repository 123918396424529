@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@value navyBlue from './common/css/common.module.css';

body {
  margin: 0;
  font-family: 'basic-sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  line-height: 32px;
  
}
html {
  overflow-x: hidden;
  font-family: sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'basic-sans', sans-serif;
  background-color:  #fefefe;
  color: #303A49;
}

.slick-next:before, .slick-prev:before {
  color: #445566;
  display: none
}

.slick-arrow{
  color: #445566;
  display: none !important;
}

.slick-dots {
  display: flex !important;
}

.slick-active {
  border: 2px solid #364ae7;
  border-radius: 10px;
  overflow: hidden;
}

.slick-list .slick-active {
  border: 0;
}

.slick-dots li {
  flex: 1 1;
  cursor: pointer;
}

ul.slick-dots {
  padding: 0;
  list-style: none;
}

b {
  color: #000;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-collapse {
  float: right;
  height: auto;
}

.navbar-toggler {
  float: right;
}

h1 {
  font-family: 'upgrade', sans-serif;
  font-weight: 500;
  color: #111825;
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

h2 {
  font-family: 'upgrade', sans-serif;
  font-weight: 500;
  color: #111825;
  font-size: 30px;
  margin-bottom: 20px;
  text-transform: uppercase;

}

mark {
  background-color: #fff;
}

b {
  background-color: #3f53d121;
  padding: 5px;
  font-weight: 500;
  
}

h4 {
    font-size: 18px;
    color: #1f3e54;
    font-weight: 600;
    position: relative;
    display: inline-block;
    align-self: flex-start;
}

#gooey-nav-svgs {
  position: absolute;
  bottom: 0;
  z-index: -1;
}
.quote {
  border-left: 2px solid #dfdfdf;
  font-style: italic;
  padding: 20px;
}
@media screen and (max-width: 991px) {
  .navbar-collapse {
    float: none;
  }

}



.Header_navbar__1DVVU {
    background: transparent;
    position: absolute;
    width: 100%;
    z-index: 99999999;
    top: 0;
    font-weight: 600;
    font-family: 'basic-sans', sans-serif;
}

.Header_container__7YgNF {
    max-width: 1200px;
    display: flex;
    width: 100%;
    margin: auto;
    padding: 1rem;

}

.Header_navbar__1DVVU nav {
    padding: 0;
    font-weight: 400;
}

.Header_navbar__1DVVU .navbar-collapse {
    text-align: left;
}

.Header_brand__9xRpE {
    display: flex;
    align-items: center
}

.Header_active__33kjv {
    color: #384EDD !important;
    font-weight: 600 !important;
}

.Header_brand_logo__2dCq9 {
    width: 60px;
    margin-right: 10px;
}

.Header_brand_title__13kzA {
    font-family: 'sofia-pro';
    letter-spacing: 1px;
    font-weight: 700;
    color: #303A49;
}

.navbar-toggle {
    border: 0;
    border-color: transparent !important;
    cursor: pointer;
    color: #005CFF;
}

.navbar-toggler-icon {
    color: #005CFF

}

.nav-link {
    padding: 0 1.5rem !important;

}

.navbar-light .navbar-nav .nav-link {
    color: #303A49

}

.navbar-toggler {
    background-color: #fff !important;
}



@media screen and (max-width: 1501px) {
    .navbar-nav {
        background-color: #fff !important;
        border-radius: 100px;
    }

}
@media screen and (max-width: 991px) {
    .Header_container__7YgNF {
        display: block
    }

    .Header_navbar__1DVVU .navbar-brand {
        float: left
    }
    .Header_navbar__1DVVU .navbar-toggler {
        float: right
    }
    .Header_navbar__1DVVU .navbar-collapse {
        clear: both;
        background-color: #fff;
        padding: 10px;
    }

}
.Footer_footer__3V1cF {
    background: rgb(34, 39, 46);
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Footer_card__3_UGl {
    display: flex;
    border-radius: 0px;
    padding: 20px;
    height: 250px;
    text-align: center;
    flex-grow: 1;
    margin: 15px;
    min-width: 300px;
    flex-direction: column;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
}

.Footer_card__3_UGl:last-child {
    flex-grow: 0;
    width: auto
}

.Footer_card__3_UGl img {
    margin: auto;
}

.Footer_card__3_UGl:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 1;
}

.Footer_title__3oPUL {
    margin: 30px;
    margin-top: auto;
}

.Footer_title__3oPUL h4 {
    font-size: 16px;
    font-family: 'basic-sans', sans-serif;
    margin-top: 30px;
    color: rgb(23, 27, 41);
}
.Footer_title__3oPUL h6 {
    font-size: 12px;
    font-family: 'basic-sans', sans-serif;
    color: rgb(56, 59, 73);
}

.Footer_more_projects__2bVeA {
    background-color: #eef4ff;
    padding: 100px 20px;
    margin-top: 100px;
}

.Footer_more_projects_inner__13MpG {
    max-width: 1100px;
    margin: auto;
}

.Footer_container__2tR68 {
    text-align: center
}

.Footer_contain__26T-6 {
    display: flex;
    flex-wrap: wrap;
}

.Footer_container__2tR68 i{
    margin: 40px;
    color: #fff8;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.Footer_container__2tR68 i:hover{
    color: #fff;
    -webkit-transform: scale(1.2);
            transform: scale(1.2)
}

.Footer_footer__3V1cF p {
    font-weight: 100;
    color: #fff8;
    font-family: 'Roboto';
    font-size: 14px;
}

@media screen and (max-width: 774px) {
    .Footer_more_projects__2bVeA {
        padding: 5rem 10%;
    }

}

@media screen and (max-width:  474px) {
    .Footer_card__3_UGl {
        margin: 15px 0;
        min-width: 200px;
    }

}
.Home_jumbotron__ue5kZ {
  height: auto;
  width: 100%;
  position: relative;
  display: flex;
  background-color: #F0F2F6;
  z-index: -1;
}

.Home_intro_header__1Uou6 {
  font-size: 14px;
  color: #51596e;
  font-family: sofia-pro;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.Home_subtext__2CPSV {
  font-size: 1.8rem;
  line-height: 3.8rem;
  font-family: 'sofia-pro';
  color:#55607a;
  font-weight: 300;
}

.Home_hp1__THtc8 {
  position: absolute;
  right: 0;
  top: -50px;
  z-index: -1;
  max-width: 250px

}

.Home_hp2__2iYFc {
  position: absolute;
  left: 0;
  top: 10%;
  z-index: -1;
  max-width: 270px;
}
.Home_mouse__jM6XK {
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 30px;
}

.Home_subtext__2CPSV b {
  color: #3a4fdd;
}

.Home_bottom_shadow__2duvm {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 10%; /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)),to(rgba(255,255,255,1)));
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

}

.Home_filter__2iOQn {
  margin: 0 20px;
}
.Home_filter__2iOQn label {
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}

.Home_text_change__3Tybf {
  color: #fff;
  background-color: #394EDD;
  line-height: 1px;
  padding: 0;
  display: inline;
  position: relative;
  padding: 2px;

}

.Home_subtext__2CPSV {
  background-color: #fff !important;
}

.Home_btns__3kWh0 {
  font-size: 20px;
  margin: 50px 0px;
}

.Home_btns__3kWh0 a:nth-child(2):hover {
  background-color: #b4c0ce;
}

.Home_filter__2iOQn label input {
  margin-right: 10px;
}

.Home_typist__28qXH {
  display: inline;
}
.Home_info__4RHRY {
  z-index: 9999999;
  width: 1200px;
  margin: auto;
  padding: 0 30px;
  font-size: 3em;
  color: #303A49;
  margin: 200px auto;
  margin-bottom: 150px;
}

.Home_top_logo__1MzY6 {
  width: 100px;
  display: block;
  margin-right: 30px;
}

.Home_text_bg__2YMg7 {
  line-height: 1.5em;
  font-family: sofia-pro;
  color: #14234A;
  display: block;
  font-weight: 600;
  margin-bottom: 20px;
}

.Home_btns__3kWh0 a:nth-child(2) {
  margin-left: 20px;
  color: #303A49;
  background-color: #cad3dd
}

.Home_project_container__VXD92 {
  max-width: 1200px;
  margin: 30px auto;
  margin-top: -80px;
  display: flex;
  flex-wrap: wrap !important;
}

.Home_project__3Gp-Z, .Home_project2__2eY9T {
  background: #EBF3FF;
  border-radius: 10px;
  flex: 1 1;
  padding: 40px;
  position: relative;
  min-height: 400px;
}

.Home_project_inner__207cH, .Home_project_hover__3dmpP {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.Home_project_hover__3dmpP {
  background: rgba(42, 50, 63, 0.8);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.Home_project_hover__3dmpP h3 {
  color: white;
  text-align: center;
  font-family: 'Roboto Slab'
}

.Home_project_hover__3dmpP div {
  width: 100%;
  text-align: center;
}

.Home_project_hover__3dmpP p {
  color: white
}

.Home_width__3UKOT {
  width: auto;
  height: inherit;

}

.Home_height__3E1Xy {
  width: inherit;
  height: auto;

}

.Home_project__3Gp-Z:hover .Home_project_hover__3dmpP, .Home_project2__2eY9T:hover .Home_project_hover__3dmpP {
  opacity: 1;
}

.Home_project__3Gp-Z:nth-child(1) {
  flex: 0 0 60%;
  margin-right: 30px;
}

.Home_project2__2eY9T:nth-child(2) {
  flex: 0 0 60%;
  margin-left: 30px;
}

.Home_project__3Gp-Z img, .Home_project2__2eY9T img {
  width: 50%;
  margin: auto;
}

.Home_content__AWDCB {
  margin: auto;
  max-width: 1200px;
}


.Home_old_content__2CQUS {
  margin: auto;
  max-width: 1200px;
  margin-top:100px;
}

.Home_old_content__2CQUS .Home_project_container__VXD92 {
  margin-top: 20px;
}


.Home_old_content__2CQUS h6 {
  background-color: #3749E6;
  padding: 5px;
  color: #fff;
  width: auto;
  display: inline-block;
  font-size: 14px;
  line-height: 1rem;
  margin-left: 20px;

}

.Home_old_content__2CQUS h1 {
  font-size: 40px;
  text-transform: none;
  margin-left: 20px;
}

.Home_filter__2iOQn {
  display: flex;
  align-items: center;
}

/* Customize the label (the container) */
.Home_filter_container__1D0Fi {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 16px;
}

/* Hide the browser's default checkbox */
.Home_filter_container__1D0Fi input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.Home_checkmark__2TZH5 {
  height: 15px;
  width: 15px;
  border: 2px solid #0074ff;
  margin-right: 10px;
}

/* On mouse-over, add a grey background color */
.Home_filter_container__1D0Fi:hover input ~ .Home_checkmark__2TZH5 {
  background-color: #0074ff11;
}

/* When the checkbox is checked, add a blue background */
.Home_filter_container__1D0Fi input:checked ~ .Home_checkmark__2TZH5 {
  background-color: #ffffff;
  
}

/* Create the checkmark/indicator (hidden when not checked) */
.Home_checkmark__2TZH5:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.Home_filter_container__1D0Fi input:checked ~ .Home_checkmark__2TZH5:after {
  display: block;
}

.Home_cont__2Lgwl {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.Home_other_cont__2jXUc {
  flex-wrap: wrap;
  display: flex
}

/* Style the checkmark/indicator */
.Home_filter_container__1D0Fi .Home_checkmark__2TZH5:after {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 15px;
  content: '\2714';
  height: 5px;
  color: #0074ff;
  border: solid white;
  border-width: 0 3px 3px 0;
}

.Home_vaxx__2dpgt img {
  width: 100%;
  height: auto;
}

.Cursor--blinking {
    opacity: 1;
    -webkit-animation: Home_blink__xSUys 1s linear infinite;
            animation: Home_blink__xSUys 1s linear infinite;
}

.Home_other_cont__2jXUc a {
  display: flex;
  flex: 0 0 30.5% !important;
}

@-webkit-keyframes Home_blink__xSUys {
      0% { opacity:1; }
      50% { opacity:0; }
      100% { opacity:1; }
}

@keyframes Home_blink__xSUys {
      0% { opacity:1; }
      50% { opacity:0; }
      100% { opacity:1; }
}

@media screen and (max-width: 1000px) {
  .Home_text_out__145ms {
    display: block;
  }
  .Home_text_change__3Tybf {
    margin: 0;
    height: 43px !important;
  }


}

@media screen and (max-width: 1280px) {

  .Home_hp2__2iYFc {
    top: -40%;
  }
}

@media screen and (max-width: 991px) {
  .Home_project_container__VXD92 {
    flex-direction: column;
  }
  .Home_project_container__VXD92 a {
    margin: 20px;
  }
  .Home_moment__3GiXA img{
    height: auto !important;
    width: 100% !important;
  }
  .Home_undraw__nEb0z img{
    height: 100% !important;
    width: auto !important;
  }
  .Home_vaxx__2dpgt img {
    width: 80% !important;
    height: auto !important;
  }
  .Home_design__1MAHf img{
    width: 80% !important;
  }
  .Home_walden__2Wbk2 img{
    width: 60% !important;
  }
  .Home_uoft__2RAQL img{
    width: 40% !important;
    height: auto !important;
  }

}

@media screen and (max-width: 620px) {
  .Home_text_bg__2YMg7 {
    font-size: 30px;
  }
  .Home_subtext__2CPSV {
    font-size: 20px;
    line-height: 40px;
  }

  .Home_uoft__2RAQL img{
    width: 70% !important;
    height: auto !important;
  }
}
@media screen and (max-width: 611px) {
  .Home_filter__2iOQn {
    flex-direction: column;
  }

  .Home_filter_container__1D0Fi {
    padding: 0
  }

}
@media screen and (max-width: 562px) {
  .Home_hp1__THtc8 {
    max-width: 150px;
  }
  .Home_hp2__2iYFc {
    max-width: 170px;
    top: 15%;
  }
}
@media screen and (max-width: 466px) {
  .Home_info__4RHRY {
    flex-direction: column;
    padding: 20px;
  }
  .Home_text_bg__2YMg7 {
    font-size: 30px;
    border: 0;
  }

  .Home_top_logo__1MzY6 {
    margin: auto;
  }
}
.ProjectThumbnail_project_container__qWDrn {
  max-width: 1200px;
  margin: 30px auto;
  display: flex;
}

.ProjectThumbnail_project__3uDYz, .ProjectThumbnail_project2__LpIgF {
  background: #FFD75C;
  border-radius: 10px;
  flex: 1 1;
  position: relative;
  max-height: 500px;
  min-height: 500px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  flex-grow: 1;
  border: 1px solid #E3E3E3;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.ProjectThumbnail_project_old__1vpUk{
  background: #FFD75C;
  border-radius: 10px;
  flex: 1 1;
  position: relative;
  max-height: 500px;
  min-height: 500px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  flex-grow: 1;
  align-items: center;
  border: 1px solid #E3E3E3;
  overflow: hidden;
  display: flex;
  justify-content: center;
}


.ProjectThumbnail_image__3dVgy {
  max-height: 400px;
  height: auto;
  height: initial;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProjectThumbnail_info__XraJ8 {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top: none;
  -webkit-filter: url('#shadowed-goo');
          filter: url('#shadowed-goo');

}

.ProjectThumbnail_used__23Iha {
  position: absolute;
  background: #fff;
  right: 20px;
  padding: 20px;
  border-radius: 120px;
  top: -40px;
}

.ProjectThumbnail_info__XraJ8 h3 {
  color: #14234A;
  margin: 0;
  font-weight: 500;
}

.ProjectThumbnail_info__XraJ8 p {
  font-size: 14px;
  margin: 0;
  line-height: 1.5rem;
  color: #424d58;
  margin-top: 10px;
  
}

.ProjectThumbnail_project_hover__pl1FE {
  background: rgba(42, 50, 63, 0.8);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ProjectThumbnail_project_old__1vpUk h3 {
  font-family: Sofia Pro, sans-serif;
  font-weight: 600px;
}

.ProjectThumbnail_project_old__1vpUk h6 {
  font-family: Sofia Pro, sans-serif;
}

.ProjectThumbnail_hp1__eLEHI {
  position: absolute;
  right: 0;
  top: -50px;
  max-width: 250px

}

.ProjectThumbnail_hp2__LQ5vF {
  position: absolute;
  left: 0;
  top: 10%;
  max-width: 270px;
}

.ProjectThumbnail_project_hover__pl1FE h3 {
  color: white;
  text-align: center;
  font-family: 'Roboto Slab'
}

.ProjectThumbnail_project_hover__pl1FE div {
  width: 100%;
  text-align: center;
}

.ProjectThumbnail_project_old__1vpUk {
  flex-direction: column;
  color: #14234A;
}

.ProjectThumbnail_project_old__1vpUk:hover {
  flex-direction: column;
  color: #14234A;
}

.ProjectThumbnail_project_hover__pl1FE p {
  color: white
}
/* 
.project:hover .project_hover, .project2:hover .project_hover {
  opacity: 1;
} */

.ProjectThumbnail_project__3uDYz:hover, .ProjectThumbnail_project_old__1vpUk:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  box-shadow: 5px 17px 40px -10px #bcbfce;

  text-decoration: none;
}

.ProjectThumbnail_project__3uDYz, .ProjectThumbnail_project_old__1vpUk {
  flex: 0 1 47% !important;
  margin: 15px;
  flex-grow: 1;
}

.ProjectThumbnail_project__3uDYz img, .ProjectThumbnail_project2__LpIgF img {
  margin: auto;
}

.ProjectThumbnail_content__2CBVo {
  margin-top: 100px;
}


@media screen and (max-width: 1000px) {
  .ProjectThumbnail_project_container__qWDrn {
    flex-direction: column;
  }
  .ProjectThumbnail_project_container__qWDrn a {
    margin: 40px;
  }

  .ProjectThumbnail_project__3uDYz {
    height: auto;
    flex: 1 1 100% !important;
  }
}

@media screen and (max-width: 663px) {
  .ProjectThumbnail_width__ttIzK.ProjectThumbnail_project__3uDYz img {
    height: auto !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 620px) {
  .ProjectThumbnail_text_bg__2tD3B {
    font-size: 30px;
  }
}
@media screen and (max-width: 466px) {
  .ProjectThumbnail_info__XraJ8 {
    flex-direction: column;
    text-align: center;
  }
  .ProjectThumbnail_text_bg__2tD3B {
    font-size: 30px;
    border: 0;
    border-top: 6px solid orange;
    padding: 20px;
  }

  .ProjectThumbnail_top_logo__3aebG {
    margin: auto;
  }
}
.themedGooeyNav4_menu__3zD43 {
   margin-top: -65px;
}
 .themedGooeyNav4_menu-item__2HSCw {
	 background: #fff;
	 border-radius: 100%;
	 width: 50px;
	 height: 50px;
	 position: absolute;
	 top: 20px;
	 color: #000;
	 text-align: center;
	 -webkit-transform: translate3d(0, 0, 0);
	         transform: translate3d(0, 0, 0);
   -webkit-transition: -webkit-transform ease-out 200ms;
   transition: -webkit-transform ease-out 200ms;
   transition: transform ease-out 200ms;
   transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
   display: flex;
}
 .themedGooeyNav4_menu-open__1BdMg {
	 display: none;
}
 .themedGooeyNav4_menu__3zD43 {
	 position: absolute;
	 right: 70px;
	 width: auto;
	 box-sizing: border-box;
	 font-size: 20px;
	 text-align: left;
}
 .themedGooeyNav4_menu-item__2HSCw {
	 overflow: hidden;
}
 .themedGooeyNav4_menu-item__2HSCw:after {
	 content: "";
	 display: block;
	 position: absolute;
	 width: 100%;
	 height: 100%;
	 top: 0;
	 left: 0;
	 pointer-events: none;
	 background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
	 background-repeat: no-repeat;
	 background-position: 50%;
	 -webkit-transform: scale(10, 10);
	         transform: scale(10, 10);
	 opacity: 0;
	 -webkit-transition: opacity 1s, -webkit-transform 0.5s;
	 transition: opacity 1s, -webkit-transform 0.5s;
	 transition: transform 0.5s, opacity 1s;
	 transition: transform 0.5s, opacity 1s, -webkit-transform 0.5s;
}
 .themedGooeyNav4_menu-item__2HSCw:active:after {
	 -webkit-transform: scale(0, 0);
	         transform: scale(0, 0);
	 opacity: 0.2;
	 -webkit-transition: 0s;
	 transition: 0s;
}
 .themedGooeyNav4_menu-item__2HSCw:nth-child(3) {
	 -webkit-transition-duration: 180ms;
	         transition-duration: 180ms;
}
 .themedGooeyNav4_menu-item__2HSCw:nth-child(4) {
	 -webkit-transition-duration: 180ms;
	         transition-duration: 180ms;
}
 .themedGooeyNav4_menu-item__2HSCw:nth-child(5) {
	 -webkit-transition-duration: 180ms;
	         transition-duration: 180ms;
}
 .themedGooeyNav4_menu-item__2HSCw i {
	 display: inherit !important;
	 padding-top: 15px;
	 width: 100%;
}
 .themedGooeyNav4_menu-item-span__3zTwA {
	 font-size: 16px;
	 text-align: center;
	 width: 100%;
}
 
.themedGooeyNav4_menu-item__2HSCw {
	 -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
	         transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);

}

.themedGooeyNav4_menu-item__2HSCw:nth-of-type(1) {
	 -webkit-transition-duration: 190ms;
	         transition-duration: 190ms;
	 right: 70px;
	 
}
.themedGooeyNav4_menu-item__2HSCw:nth-of-type(3) {
	 -webkit-transition-duration: 290ms;
	         transition-duration: 290ms;
}
.themedGooeyNav4_menu-item__2HSCw:nth-of-type(2) {
	 -webkit-transition-duration: 390ms;
	         transition-duration: 390ms;
	 right: 10px;
}
 
.MyProcess_container__1_2wh {
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;
  font-size: 16px;
  padding: 20px;
  color: rgb(29, 36, 63);
}

.MyProcess_container__1_2wh b {
  background: transparent;
  color: #384EDD;
  font-weight: 700;
  padding: 0;
}

.MyProcess_title_container__Y0OOT {
  background-color: #F0F4F8;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 50px;
}

.MyProcess_title_container_inner__2_ayH {
  max-width: 80%;
  margin: auto;
  display: flex;
}


.MyProcess_hp1__69JAI {
  position: absolute;
  right: 0;
  top: -50px;
  z-index: -1;
  max-width: 150px

}

.MyProcess_hp2__3t5AG {
  position: absolute;
  left: 0;
  top: 20%;
  z-index: -1;
  max-width: 150px;
}

.MyProcess_title_container_inner__2_ayH h5 {
  color: #384EDD;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  letter-spacing: 2px;
}

.MyProcess_quote__25_fi {
  font-size: 35px;
  font-weight: 600;
  line-height: 50px;
  margin-left: 20px;
}

.MyProcess_accent__2MIKx {
  border-radius: 90px;
  border-top-right-radius: 0px;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #0D1533;
  width: 70px;
  height: 70px;
  line-height: 50px;
}

.MyProcess_concept_container__13ova {
  display: flex;
  margin-top: 50px;
}

.MyProcess_concept_container__13ova div {
  flex: 1 1;
}

.MyProcess_concept_container__13ova div:nth-child(2) {
  margin: 0px 70px
}

.MyProcess_container__1_2wh h4 {
  font-size: 19px;
  margin: 5px 0px;
  font-weight: 700;
}

.MyProcess_container__1_2wh hr {
  margin: 50px 0;
}

.MyProcess_process_container__2hneM {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 100px auto;

}

.MyProcess_process_container__2hneM div {
  flex: 1 1;
  min-width: 44%;
  margin-bottom: 20px;
}

.MyProcess_process_container__2hneM div:nth-child(odd) {
  margin-right: 40px;
}

@media screen and (max-width: 1416px) {
  .MyProcess_hp1__69JAI, .MyProcess_hp2__3t5AG {
    display: none;
  }

}

@media screen and (max-width: 740px) {
  .MyProcess_title_container_inner__2_ayH {
    max-width: 100%;
  }

}


@media screen and (max-width: 645px) {
  .MyProcess_concept_container__13ova {
    flex-direction: column;
  }

  .MyProcess_concept_container__13ova div:nth-child(2) {
    margin: 20px 0px
}

}
@media screen and (max-width: 515px) {
  .MyProcess_process_container__2hneM {
    flex-direction: column;
  }

  .MyProcess_quote__25_fi {
    font-size: 25px;
    line-height: 35px;
  }
}
@media screen and (max-width: 445px) {
  .MyProcess_accent__2MIKx {
    width: 50px;
    height: 50px;
    padding: 20px;
  }

  .MyProcess_title_container__Y0OOT, .MyProcess_title_container_inner__2_ayH {
    flex-direction: column;
  }

  .MyProcess_quote__25_fi {
    margin: 20px 0px 0px 0px;
  }
}
.Illustrations_project_container__2vc7j {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.Illustrations_project_overview__2urvt {
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;
  
}
.DaysType_project_container__1Wkek {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.DaysType_img__32Obn  {
  width: 30%;
  margin: 10px;
  border-radius: 10px;
}

@media screen and (max-width: 640px) {
  .DaysType_img__32Obn {
    width: 100%;
  }

}
.Projects_basic_margin__2JoTB {
  max-width: 900px;
  margin: 1.8rem auto;
  padding: 20px;
}

.Projects_basic_padding__14mg0 {
  padding: 1.8rem 20%;
}

.Projects_persona__2qgji {
  display: flex;
}

.Projects_title_flex__2hHsY {
  margin-top: 120px
}

.Projects_quote__jXcHd {
  quotes: "“" "”" "‘" "’";
  font-size: 20px;
  display: flex;
  align-items: center;
  font-style: italic;
  justify-content: center;
  margin: 30px 0px;
}

.Projects_quote__jXcHd::before, .Projects_quote__jXcHd::after {
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #0074ff;
  font-size: 90px;
  margin-bottom: -40px;
  padding: 20px;
}
.Projects_quote__jXcHd::before {
  content: open-quote;
}
.Projects_quote__jXcHd::after {
  content: close-quote;
}

.Projects_source__2q55M {
  display: flex;
  align-self: flex-end;
}

.Projects_quote_container__2BOl8 {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.Projects_quick_info__1FD5k h5 {
  font-family: 'Roboto Slab';
  font-weight: 900;
  color: #213e7a;
  text-transform: uppercase;
}

.Projects_title_flex__2hHsY h5{
  color: #0064e5;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  margin-bottom: 20px;
}

.Projects_table_stats__2mvRq {
  width: 600px;
}

.Projects_final_design__3jpa9 {
  background: #F0F2F6;
  
}

.Projects_box__2fEx8 {
  border: 1px dashed #9ea9b5;
  border-radius: 20px;
  padding: 70px;
  margin: 5rem auto;
  text-align: center;
}

.Projects_box__2fEx8:nth-child(1) {
  margin-right: 20px;
}

.Projects_changes_title__251_N h4 {
  font-size: 1rem;
  color: #0074ff !important;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Roboto Slab';
  letter-spacing: 2px
}

.Projects_changes_title__251_N h3 {
  font-size: 1.5rem;
}

.Projects_table__1pV8_ {
  width: 100%;
  box-shadow: 6px 5px 20px 0px rgb(207, 215, 230);
  border-radius: 5px;
  padding: 30px;
  overflow-x: scroll;
}

.Projects_table__1pV8_ table {
  width: 900px;
}

.Projects_table__1pV8_ td , .Projects_table__1pV8_ th{
  padding: 20px;
  
}

.Projects_table__1pV8_ th {
  text-align: center
}

.Projects_table__1pV8_ td {
  width: 25%;
  border-top: 1px solid #dbe3ee;
}

.Projects_table__1pV8_ tr td:nth-child(1) {
  text-align: center;
}

.Projects_infos__WZGui {
  background: #EBF3FF;
  width: 100%;
  padding: 40px;
  display: flex;
  border-radius: 20px;
}

.Projects_infos__WZGui div {
  margin: 20px;
  flex: 1 1;
}

.Projects_infos__WZGui h1 {
  text-align: center;
  font-size: 40px;
  color: #0074ff;
  font-weight: 700;
}

.Projects_process__3hDb7 {
  display: block;
  margin: 0px auto 80px;
}

.Projects_projects__Bfo9G {
  /* max-width: 1000px;
  width: 80%; */
  padding: 1.8rem 20%;
  margin: auto;
  color: #515767;
}

.Projects_section__1OAu9 {
  margin: 80px 0px;
}
.Projects_tools__1LqLl {
  background-color:  #67718011;
}

.Projects_product_mock__Vsutv {
  background-color: #ebedf1;
  min-height: 600px;
  height: auto;
  max-width: 1000px;
  margin: auto;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Projects_product_mock__Vsutv img {
  max-width: 500px;
}

.Projects_project__KmSo_ {
  margin-top: 200px;
  font-family: 'basic-sans', sans-serif;
  color: #515767;
  line-height: 1.7;

}

.Projects_hp1__3C-a8 {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 200px;
}

.Projects_hp2__m54-5 {
  position: absolute;
  top: 10%;
  left: 0;
  max-width: 200px;
}


.Projects_project__KmSo_ h1 {
  font-size: 3rem;
  font-weight: 400;
  margin: 0;
  text-transform: none;
  font-family: 'basic-sans', sans-serif;
}

.Projects_project__KmSo_ h6 {
  background-color: #3749E6;
  padding: 5px;
  color: #fff;
  width: auto;
  display: inline-block;
  font-size: 14px;
  line-height: 1rem;

}


.Projects_basic_margin__2JoTB h1, .Projects_basic_padding__14mg0 h1 {
  font-size: 2.5rem !important;
  margin-bottom: 20px;
}

.Projects_project__KmSo_ h5 {
  font-weight: 500;
  margin: 0;
  font-size: 14px;
  color: #898DA3;
}


.Projects_tools__1LqLl {
  text-align: left;
  margin: 3rem auto;
}

.Projects_info__2rwtV {
  padding: 40px 10px;
  background-color: #F0F2F6;
  width: 100%;
}

.Projects_button__3nBEQ {
  display: block;
    background-color: #364ae6;
    margin: auto;
    color: #fff !important;
    padding: 5px 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}

.Projects_button__3nBEQ:hover {
  color: #fff;
  text-decoration: none;
  background-color: #2132b8;
}

.Projects_tool_icon__18ORd {
  width: 25px;
  margin-right: 10px;
}

.Projects_info__2rwtV h5  {
  color: #1D222F;
  font-weight: 700;
  font-size: 1.1rem !important;
  letter-spacing: normal;
  margin-bottom: 10px;
}

.Projects_tools__1LqLl div {
  flex: 1 1;
  
}

.Projects_tools__1LqLl h2, .Projects_prob_sol__uv2tL h2 {
    font-size: 25px;
    line-height: 1.5;
    color: #293846;
    margin-bottom: 10px;
    font-family: 'Sofia Pro';
    font-weight: 600;
    text-transform: none;
}
.Projects_prob_sol__uv2tL {
  display: flex;
}

.Projects_prob_sol__uv2tL div {
  flex: 1 1;
}

.Projects_tools__1LqLl {
  display: flex;
}
.Projects_tools__1LqLl div {
  flex: 1 1;
}

.Projects_prob_sol__uv2tL div:nth-child(1) {
  padding-right: 20px;
}

.Projects_quick_info__1FD5k div {
  flex: 1 1;
  text-align: center;

}

.Projects_quick_info__1FD5k {
  margin: auto;
  display: flex;
  flex-direction: row;

}

.Projects_quick_info__1FD5k h1 {
  margin: 0;
    display: inline-block;
    font-size: 1rem !important;
    font-weight: 600;
    text-transform: uppercase;
    color: #1D222F;
    margin-bottom: 20px;
    border-bottom: 2px solid #364ae6;
    padding-bottom: 10px;
}

.Projects_quick_info__1FD5k h5 {
  font-family: 'sofia-pro';
  font-weight: 700;
  color: rgb(49, 57, 77);
  font-size: 16px;
  border-bottom: 1px solid orange;
  padding-bottom: 10px;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}


.Projects_tools__1LqLl span::after {
  content: ', '
}

@media screen and (max-width: 774px) {
  .Projects_tools__1LqLl {
    flex-direction: column;
  }

}

@media screen and (max-width: 587px) {
  .Projects_quick_info__1FD5k {
    flex-direction: column;
  }
}
@media screen and (max-width: 460px) {
  .Projects_prob_sol__uv2tL {
    flex-direction: column;
  }
  .Projects_prob_sol__uv2tL div:nth-child(1) {
    padding: 0;
  }
  .Projects_prob_sol__uv2tL div:nth-child(2) {
    margin-top: 3rem;
  }
}


.Projects_inner__wLARS {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 700px;
  height: 100%;
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}

.Projects_process__3hDb7 {
  width: 100%;
  text-align: center;
}

.Projects_box__2fEx8 .Projects_title_flex__2hHsY {
  margin: 0px !important;
}

@media screen and (max-width: 1020px) {
  .Projects_product_mock__Vsutv {
    margin: 0px 20px;
  }
}
@media screen and (max-width: 883px) {
  .Projects_box_cont__I6xPe {
    flex-direction: column
  }
}

@media screen and (max-width: 774px) {
  .Projects_projects__Bfo9G {
    padding: 20px;
  }

  .Projects_basic_margin__2JoTB {
    margin: 1.8rem 5%;
  }
  .Projects_basic_padding__14mg0 {
    padding: 1.8rem 10%;
  }

  .Projects_inner__wLARS {
    margin: 1.8rem 10%;
  }
}


@media screen and (max-width: 789px) {
  .Projects_infos__WZGui {
    flex-direction: column;
    text-align: center;
  }
}
@media screen and (max-width: 616px) {
  .Projects_hp1__3C-a8, .Projects_hp2__m54-5 {
    z-index: -1;
  }
}
@media screen and (max-width: 610px) {
  .Projects_product_mock_inner__e-jFk {
    flex-direction: column
  }

  .Projects_product_mock_inner__e-jFk div:nth-child(1){
    text-align: center;
    max-width: 300px;
  }
  .Projects_product_mock_inner__e-jFk div:nth-child(1){
    order: 1
  }
  .Projects_process__3hDb7 img {
    width: 100% !important;
  }
}
@media screen and (max-width: 580px) {
  .Projects_inner__wLARS {
    flex-direction: column;
  }
  .Projects_hp1__3C-a8, .Projects_hp2__m54-5 {
    width: 150px;
  }
}

@media screen and (max-width: 410px) {

  .Projects_quick_info__1FD5k {
    flex-direction: column;
    margin: 20px;
  }
  .Projects_quick_info__1FD5k div {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .Projects_process__3hDb7 img {
    width: 100% !important;
  }
    .Projects_hp1__3C-a8, .Projects_hp2__m54-5 {
    width: 100px;
  }
}
.Misc_project_container__8pAjQ {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
.UIChall_project_container__2qiH0 {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
.Publications_project_container__3Nkgx {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  margin-top: 100px;
}

.Publications_item__2Xld7 {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #44556622;
  margin: 20px 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

}

.Publications_item__2Xld7:hover {
  box-shadow: 5px 17px 40px -10px #bcbfce;
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
  text-decoration: none;
}

.Publications_item_inner__fx3lp img {
  width: 100%;
}

.Publications_avatar__2raQ_ img {
  width: 50px;
  border-radius: 100px;
}

.Publications_item_inner__fx3lp {
  flex: 1 1;
  background-size: cover;
  background-position: center;
}

.Publications_info__OFJ4n {
  padding: 40px;
}

.Publications_title__RVKEg {
  font-size: 25px;
  line-height: 1.5;
  color: #293846;
  font-weight: 500;
  margin-bottom: 10px;
}

.Publications_date__2sDr2 {
  margin-top: 20px;
  color: #9fa8b0;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
}
.Publications_subtitle__FjdRy {
    color: #657480;
    line-height: 1.5;

}

@media screen and (max-width: 540px) {
  .Publications_item__2Xld7 {
    flex-direction: column;
  }

  .Publications_item_inner__fx3lp {
    flex: 0 0 300px
  }
}




.ProjectInfo_tools__38ZOn {
  text-align: left;
  margin: 3rem auto;
}

.ProjectInfo_info__25LTK {
  border-bottom: 1px solid #d7dae2;
  padding-bottom: 40px;
  margin-top: 0px;
  padding-top: 0px;

}

.ProjectInfo_tool_icon__3Hxe3 {
  width: 25px;
  margin-right: 10px;
}

.ProjectInfo_info__25LTK h5  {
  color: #1D222F;
  font-weight: 700;
  font-size: 1.1rem !important;
  letter-spacing: normal;
  margin-bottom: 10px;
}

.ProjectInfo_tools__38ZOn div {
  flex: 1 1;
  
}

.ProjectInfo_tools__38ZOn h2, .ProjectInfo_prob_sol__1dVdI h2 {
    font-size: 25px;
    line-height: 1.5;
    color: #293846;
    margin-bottom: 10px;
    font-family: 'Sofia Pro';
    text-transform: none;
}
.ProjectInfo_prob_sol__1dVdI {
  display: flex;
}

.ProjectInfo_prob_sol__1dVdI div {
  flex: 1 1;
}

.ProjectInfo_tools__38ZOn {
  display: flex;
}
.ProjectInfo_tools__38ZOn div {
  flex: 1 1;
}

.ProjectInfo_prob_sol__1dVdI div:nth-child(1) {
  padding-right: 20px;
}

.ProjectInfo_quick_info__2F4Jx div {
  flex: 1 1;
  text-align: center;

}

.ProjectInfo_quick_info__2F4Jx {
  margin: auto;
  display: flex;
  flex-direction: row;

}

.ProjectInfo_quick_info__2F4Jx h1 {
  margin: 0;
    display: inline-block;
    font-size: 1rem !important;
    font-weight: 600;
    text-transform: uppercase;
    color: #1D222F;
    margin-bottom: 20px;
    border-bottom: 3px solid #6b69ea;
    padding-bottom: 10px;
}

.ProjectInfo_quick_info__2F4Jx h5 {
  font-family: 'sofia-pro';
  font-weight: 700;
  color: rgb(49, 57, 77);
  font-size: 16px;
  border-bottom: 1px solid orange;
  padding-bottom: 10px;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}


.ProjectInfo_tools__38ZOn span::after {
  content: ', '
}

@media screen and (max-width: 774px) {
  .ProjectInfo_tools__38ZOn {
    flex-direction: column;
  }

}

@media screen and (max-width: 587px) {
  .ProjectInfo_quick_info__2F4Jx {
    flex-direction: column;
  }
}
@media screen and (max-width: 460px) {
  .ProjectInfo_prob_sol__1dVdI {
    flex-direction: column;
  }
  .ProjectInfo_prob_sol__1dVdI div:nth-child(1) {
    padding: 0;
  }
  .ProjectInfo_prob_sol__1dVdI div:nth-child(2) {
    margin-top: 3rem;
  }
}
.Title_title_container__3KBKp {
  margin: 20px 0px;
}


.Title_title_container_inner__-G69e {
  margin: auto;
  display: flex;
}

.Title_title_container_inner__-G69e h5 {
    margin-top: 20px;
    color: #3f4d5b;
    text-transform: uppercase;
    font-family: 'basic-sans', sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 14px;
}

.Title_title_quote__1AD_O h2 {
    font-size: 30px;
    line-height: 1.5;
    color: #293846;
    margin-bottom: 10px;
    font-family: 'basic-sans', sans-serif;
    font-weight: 500;
    text-transform: none;
    position: relative;
}

.Title_title_quote__1AD_O h2:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 1px;
    bottom: -10px;
    left: 0;
    border-bottom: 2px solid #364ae6;
}

.Title_accent__33jGm {
  border-radius: 90px;
  border-top-right-radius: 0px;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #0D1533;
  width: 60px;
  height: 60px;
  line-height: 50px;
}

@media screen and (max-width: 490px) {
  .Title_accent__33jGm {
    display: none
  }

  .Title_title_quote__1AD_O {
    margin-left: 0px;
  }
}
.Nav_moving__xGx6u {
    position: fixed;
    top: 200px;
    left: 0px;
    list-style: none;
    color: #173355;
    flex-direction: column;
    z-index: 9999;
  }
  
  .Nav_moving__xGx6u a {
    color: #173355;
  }
  
  .Nav_moving__xGx6u a:hover {
    text-decoration: none;
    color: #0074ff;
  }
  
  .Nav_moving__xGx6u li {
    padding: 3px 0;
    font-size: 13px;
  }
  
  .Nav_moving__xGx6u ul {
    list-style: none;
  }
  
  .Nav_active__1PD6c a{
    border-left: 3px solid #3549e6;
    padding-left: 20px;
    color: #3549e6;
  }
  
@media screen and (max-width: 1251px) {
    .Nav_moving__xGx6u {
      top: 0;
      left: 0;
      background: #fff;
      width: 100%;
      overflow-x: scroll;
      white-space: nowrap;
      font-size: 16px;
      display: none;
      box-shadow: 0px 0px 20px #00000022;
    }
  
    .Nav_moving__xGx6u ul {
      padding-left: 10px;
      display: flex;
      margin: 0px;
    }
  
    .Nav_moving__xGx6u li {
      display: inline-block;
      padding: 15px;
      font-size: 16px;
    }
  
    .Nav_moving__xGx6u .Nav_active__1PD6c a {
      border-bottom: 2px solid #3549e6;
      border-left: 0;
      padding: 0;
    }
  
    .Nav_moving__xGx6u::-webkit-scrollbar {
      width: 0px;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
      display: none;
  }
    /* Optional: show position indicator in red */
    .Nav_moving__xGx6u::-webkit-scrollbar-thumb {
        background: transparent;
        width: 1;
    }
  }
.Progress_progress__27_OV {
  width: 100%;
  height: 6px;
  position: fixed;
  top: 0;
  z-index: 2147483638;
}

.Progress_progress_inner__1gKdm {
  background-color: #3549e6;
  height: 100%;
}

.UofTHacks_container__1lp1g {
  max-width: 900px;
  width: 100%;
  margin: auto;
}

.UofTHacks_vax_header__3G4OV {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.UofTHacks_persona__1iKmD {
  display: flex;
}

.UofTHacks_quote__1Z5f0 {
  border-left: 3px solid #dfdfdf;
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;

  color: #999999;
  font-style: italic;
}

.UofTHacks_bg__1aA5n {
  background-color: red;
  background-image:
    radial-gradient(
      circle at top right,
      #263841,
      #0d161c 70%
    );
}

.Potluck_context__2fjy3{
  display: flex;
}

.Potluck_context_inner__u9WfB {
  margin-right: 50px;
}

.Potluck_sec_research__38cqJ img{
  width: 60px;
  margin: auto;

}

.Potluck_eval_research__8bvH5 {
  display: flex;
  flex-wrap: wrap;
}

.Potluck_eval_research__8bvH5 li {
  list-style: none;
  background-color: #6b69ea;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  text-align: center;
  margin: 10px;

}

table.Potluck_table__2oDU8 {
  border: 1px solid #334455;
  width: 100%;
}

.Potluck_table__2oDU8 tbody {
  width: 100%;
}

.Potluck_inline_tr__3NNjq {
  display: inline-table;
  width: 100%;
}

.Potluck_sec_research__38cqJ {
  flex-wrap: wrap;
}

.Potluck_sec_research__38cqJ div {
  flex: 1 1;
  padding: 10px;
}

.Potluck_grey__ZOKF4 {
  background-color: #F0F2F6;
  padding: 2rem 0px;
}

.Potluck_three_col__31F6O {
  -webkit-column-count: 3;
          column-count: 3;
}



.Potluck_three_col__31F6O ul {
  list-style-position: outside;
}

.Potluck_ques__1yKuL {
  list-style-type: decimal-leading-zero;
  list-style-position: outside;
}

.Potluck_ques__1yKuL div:nth-child(2) {
  margin: 0px 20px;
}


@media screen and (max-width: 774px) {
  .Potluck_context__2fjy3 {
    flex-direction: column;
  }

  .Potluck_context_inner__u9WfB {
    margin: 0;
    margin-bottom: 40px;
  }
  
}

.Potluck_container__13r_z {
  width: 100%;
  margin: auto;
}

.Potluck_context__2fjy3{
  display: flex;
}

.Potluck_container__13r_z h4 {
  font-weight: 100 !important;
  line-height: 38px;
  font-size: 20px;
}

.Potluck_context_inner__u9WfB {
  margin-right: 50px;
}

.Potluck_bg__5eLhR {
  width: 120%;
  position: absolute;
  bottom: 0px;
  right: -35%;
}
.Potluck_vax_header__1oFcp {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.Potluck_person__1Q8CD {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  border: 5px solid #fff;
}

.Potluck_interview__yfYKL {
  background: #dfe4ec;
  padding-bottom: 5rem;
}

.Potluck_hmw__1iG0D {
  background: #f5f7fb;
  margin-top: 40px;
  padding: 100px 0px;
  align-items: center;
  display: flex;
  align-items: center;
  position: relative;
}

.Potluck_phone_gif__cHN9x {
  border-radius: 20px;
  border: 4px solid #15395d;
  width: 250px;
  box-shadow: 4px 4px 30px #aeb4b9;
}

.Potluck_web_gif__20t9f {
  border-radius: 20px;
  border: 4px solid #15395d;
  width: 100%;
  box-shadow: 4px 4px 30px #aeb4b9;
}

.Potluck_text__3DJHQ {
  max-width: 600px;
  width: 100%;
}

.Potluck_fin_text__3o_Xf:nth-child(even) .Potluck_text__3DJHQ{
  margin-left: 40px;
}
.Potluck_fin_text__3o_Xf:nth-child(odd) .Potluck_text__3DJHQ {
  padding-right: 40px;
}

.Potluck_text_section__1hBx1 {
  margin: 7rem auto;
}

.Potluck_final__HdkA0 {
  background: #F0F2F6;
  padding: 100px 0px;
}

.Potluck_section_margin__1tey9 {
  margin-top: 150px;
  margin-bottom: 150px;
}

.Potluck_lofi__3B6M7 {
  background: #e5e5e5;
  padding: 5rem 0;
  margin-top: 40px;
  align-items: center;
  display: flex;
  align-items: center;
  position: relative;
}

.Potluck_flower__1VojB {
  position: absolute;
  width: 200px;
  right: 0;
  bottom: -10px;
  z-index: -1;
}

.Potluck_hmw__1iG0D h1 {
  widows: 100%;
  align-self: flex-start;
}

.Potluck_sketches__2fS1Q {
  display: flex;
  margin-top: 40px;
  align-items: center;
}
.Potluck_persona__1v3fd {
  display: flex;
}

.Potluck_quote__XKPdo {
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;
  font-family: 'Sofia Pro';
  color: #535b6d;
  font-style: italic;
}

.Potluck_actively_working__2YX1p, .Potluck_template_box__siPQu{
  justify-content: space-between;
}
.Potluck_final_stats__1AFYb h4 {
  color: #6b69ea;
  text-transform: uppercase;
  align-self: center;
}

.Potluck_final_end__2B5qK h2 {
  text-align: center;
    text-transform: none;
    color: #687c94;
    font-size: 20px;
    font-weight: 300;
}

.Potluck_final_stat_number__5K_1g {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Potluck_final_stat_num__2OPeL {
  position: relative;
}

.Potluck_final_stats__1AFYb .Potluck_flex_one__3KI16:nth-child(2){
  margin-left: 60px;
}

.Potluck_insights__1fQSv div {
  flex: 0 0 33%;
}

.Potluck_landing_page__3xWWO {
  width: 100%;
  height: 500px;
  margin: 60px 0px;
}

.Potluck_landing_page__3xWWO div {
  flex: 1 1;
}

.Potluck_landing_page__3xWWO .Potluck_image__24QCF {
  background-image: url('/assets/images/landingpage.jpg');
  background-size: cover;
}

.Potluck_landing__16biC.Potluck_grey__ZOKF4 {
  padding: 0;
}

.Potluck_landing_text__2xCdI {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Potluck_landing_text_inner__2deCF {
  width: 450px;
  padding-right: 50px;
}


.Potluck_actively_working__2YX1p div {
  flex: 0 0 45%;
}

.Potluck_template_box__siPQu div {
  flex: 0 0 30%;
}

.Potluck_actively_working__2YX1p div:nth-child(1) {
  margin-right: 20px;
  position: relative;
}

.Potluck_actively_working__2YX1p div:nth-child(1)::after {
content: '\2192';
    color: #fff;
    display: flex;
    display: inline-flex;
    width: 40px;
    height: 40px;
    position: absolute;
    justify-content: center;
    border-radius: 100px;
    background-color: #6b69ea;
    align-items: baseline;
    padding-top: 6px;
    right: -60px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.Potluck_final_stats__1AFYb {
  background-color: #f5f7fb;
  justify-content: center;
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 30px;

}
.Potluck_final_stats_inner__38VIw {
  justify-content: center;
}

.Potluck_final_stat_num__2OPeL::after {
  content: '\2192';
    color: #fff;
    display: flex;
    display: inline-flex;
    width: 40px;
    height: 40px;
    position: absolute;
    justify-content: center;
    border-radius: 100px;
    background-color: #6b69ea;
    align-items: baseline;
    padding-top: 6px;
    right: -40px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.Potluck_challenge__2Xj1c {
  padding: 100px 20%;
  background-color: #F0F2F6;

}

.Potluck_challenge__2Xj1c h1 {
  color: #6b69ea;
  font-weight: 600;

}
.Potluck_challenge__2Xj1c h3 {

  text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 2px;

}

.Potluck_cara_img__3NU-1 {
  box-shadow: 0 0 30px #44556622;
}

.Potluck_slider__1Ad5I {
  margin-bottom: 260px;
}

.Potluck_insight_ideas__1XY7X {
  padding: 20px;
  border-radius: 20px;
  position: relative;
  box-shadow: 6px 5px 20px 0px rgb(207, 215, 230)
}

.Potluck_insight_ideas__1XY7X ul li{
  list-style-position: outside;
}

.Potluck_insight_ideas__1XY7X ul{
  padding-left: 15px;
}

.Potluck_insight_ideas__1XY7X:nth-child(2) {
margin: 0px 20px;
}

.Potluck_insight__35W2t p {
  color: rgb(41, 61, 90);
  font-weight: 400;
}

.Potluck_title__2WIEg {
  font-family: 'Roboto Slab';
  color: #0074ff !important;
  margin-bottom: 10;
  font-weight: 900;font-size: 20px;
}

.Potluck_number__1AVY0 {
  font-family: 'Roboto Slab';
  font-size: 70px;
  color: #0074ff;
  position: absolute;
  top: 0;
  font-weight: 900;
}

.Potluck_insight__35W2t:nth-child(even) {
  margin: 0 20px;
}

.Potluck_changes_title__25YIQ h4 {
  font-size: 1rem;
  color: #0074ff !important;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Roboto Slab';
  letter-spacing: 2px
}
.Potluck_changes_title__25YIQ h3 {
  font-size: 1.5rem;
}

.Potluck_dech__2IGyD {
  padding-left: 15px;
}

ul.Potluck_dech__2IGyD li {
  list-style-position: outside;
}

.Potluck_research__3kqde {
  text-align: left;
  font-weight: 500;
  line-height: 1.4rem;
  width: 200px;
}

.Potluck_flex_one__3KI16 {
  flex: 0 0 20%;
}

.Potluck_flex_one__3KI16:not(:nth-child(1)).Potluck_flex_one__3KI16:not(:last-child) {
  margin: 0 20px;
}

.Potluck_stat__B8Flw {
  color: rgb(32, 42, 56);
  font-size: 60px;
  font-weight: 600;
  text-align: left;
}

.Potluck_sus__1x_GV {
  background-color: #f5f7fb;
  padding: 5rem 0;
  display: flex;
  align-items: center;


}

.Potluck_stats_list__3OF61 {
  margin-bottom: 100px;
}

.Potluck_idea__8L_se {
  flex-direction: column;
  flex: 0 0 46%;
  background-color: #0074ff33;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  display: flex;
  margin: 20px !important;
}

.Potluck_insight_cont__3ZmhC {
  flex-wrap: wrap;
}

.Potluck_lofi_img__1YB2f {
  width: 70%;
  margin-right: -200;
  margin-left: 40;
}

.Potluck_table__2oDU8 {
  padding: 0 !important;
}

.Potluck_overview_vid__2_HYT {
  box-shadow: rgba(68, 85, 102, 0.267) 4px 10px 90px;
  border-radius: 20px;
}

.Potluck_table__2oDU8 table {
  width: 100%;
}

.Potluck_table__2oDU8 td:not(:nth-child(1)) {
  width: 0%;
}
.Potluck_table__2oDU8 th:not(:nth-child(1)) {
  width: 0%;
}

.Potluck_table__2oDU8 td {
  padding: 5px;
  text-align: center;
}

.Potluck_table__2oDU8 tbody {
  display: table;
  padding-left: 10px;
}

.Potluck_table__2oDU8 .Potluck_red__3_t1P, .Potluck_table__2oDU8 .Potluck_green__3JDyr {
  text-align: center;
}

.Potluck_table__2oDU8 .Potluck_red__3_t1P {
  background-color: rgb(254, 213, 213);
  font-size: 25px;
  line-height: 0px;
}

.Potluck_final_results__2kuR2 {
  margin-top: 40px;
}

.Potluck_table__2oDU8 .Potluck_green__3JDyr {
  background-color: rgb(213, 254, 235);
  font-size: 16px;
  line-height: 0px;
}

.Potluck_reflect__2q6Jb {
  background-color: #f5f7fb;
  padding: 5rem 0px;
}

.Potluck_reflect_text__o_ZI3 {
  margin-right: 40px;
  flex: 1 1;
}

.Potluck_bar_graph__3OWUa {
  width: 45%;
  margin-top: 50px
}

.Potluck_bar_graph_2__3T8uo {
  width: 55%;
  margin-top: 50px
}

.Potluck_concept_feedback__Fctn- {
  display: flex;
  flex-wrap: wrap;
}

.Potluck_concept_feedback__Fctn- p {
  width: 100%;
  margin-right: 20px;
  max-width: 47%;
  margin-bottom: 30px;
  font-style: italic;
  font-family: "Sofia Pro";
  background-color: #f5f7fb;
  padding: 20px;
  border-radius: 20px;
}

.Potluck_ob_images__1lbIT {
  margin: 3rem auto;
}

.Potluck_observations__2lqyl {
  background-color: #f5f7fb;
}

.Potluck_observe__yO4Hx {
  width: 200px;
  height: auto;
  margin-right: 50px;
}

@media screen and (max-width: 1251px) {
  .Potluck_sur_stats__1AWzM {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1111px) {
.Potluck_concept_feedback__Fctn- p {
  max-width: 100%;
  margin-right: 0;

}
}

@media screen and (max-width: 1000px) {
  .Potluck_text_section__1hBx1:nth-child(odd) div:nth-child(2) {
    margin-right: -100px;
  }

  .Potluck_landing_page__3xWWO {
    flex-direction: column;
    height: 700px;
  }

  .Potluck_landing_text_inner__2deCF {
    width: 100%;
    padding: 20px;
  }

}

@media screen and (max-width: 870px) {
 .Potluck_bar_graph__3OWUa, .Potluck_bar_graph_2__3T8uo {
   width: 100%;
 }

 .Potluck_challenge__2Xj1c {
   padding: 100px 10%;
 }
}
@media screen and (max-width: 800px) {
  .Potluck_fin_text__3o_Xf {
    flex-direction: column;
    align-items: baseline;
  }

  .Potluck_fin_text__3o_Xf:nth-child(even) .Potluck_text__3DJHQ {
    margin: 0;
  }

  .Potluck_fin_text__3o_Xf:nth-child(odd) .Potluck_fin_text_first__1fCuI {
    order: 1;
  }

  .Potluck_fin_text__3o_Xf .Potluck_text__3DJHQ {
    padding-right: 0 !important;
    margin-top: 50px !important;
  }

  .Potluck_fin_text__3o_Xf:nth-child(odd) div:nth-child(2) {
    margin-right: 0px;
    
  }
}
@media screen and (max-width: 774px) {
  .Potluck_final__HdkA0 {
  }

  .Potluck_hmw__1iG0D {
    z-index: 1;
  }

  .Potluck_sketches__2fS1Q {
    flex-direction: column;
  }

  .Potluck_lofi__3B6M7 {
    flex-direction: column;
  }

  .Potluck_lofi_img__1YB2f {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
}

  .Potluck_research__3kqde {
    width: 150px;
  }

  .Potluck_actively_working__2YX1p div:nth-child(1)::after {
    display: none;
  }
}

.Potluck_progress__1anBc {
  width: 100%;
  height: 5px;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 999999999999999999999999999999;
}

.Potluck_progress_inner__2xwbT {
  background-color: #0074ff;
  height: 100%;
  /* transition: all 0.3s ease; */
}

@media screen and (max-width: 720px) {
  .Potluck_final_stat_num__2OPeL::after {
    display: none
  }
}
@media screen and (max-width: 700px) {
  .Potluck_reflect__2q6Jb div {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .Potluck_reflect_text__o_ZI3 {
    margin-right: 0;
  }

  .Potluck_sec_research__38cqJ {
    flex-direction: column;
  }


 .Potluck_challenge__2Xj1c {
   padding: 50px 0%;
 }
  
}

@media screen and (max-width: 774px) {
  .Potluck_web_gif__20t9f {
    width: 100%;
  }
  .Potluck_context__2fjy3 {
    flex-direction: column;
  }

  .Potluck_context_inner__u9WfB {
    margin: 0;
    margin-bottom: 40px;
  }

  .Potluck_template_box__siPQu {
    flex-direction: column;
  }

  .Potluck_temp_hi__1faCc {
    flex-direction: column;
  }
  
}
@media screen and (max-width: 600px) {
  .Potluck_insight_cont__3ZmhC {
    flex-direction: column;
  }

  .Potluck_card__15qTo {
    flex-direction: column;
    margin: 50px 0px;
    text-align: center;
  }

  .Potluck_insight__35W2t:nth-child(2) {
    margin: 20px 0px;
  }

  .Potluck_ob_images__1lbIT {
    flex-direction: column;
  }

  .Potluck_ob_images__1lbIT p {
    margin-top: 20px;
  }

  .Potluck_observe__yO4Hx {
    width: 100%;
  }
}


@media screen and (max-width: 578px) {
  .Potluck_interview_basic__1TipS {
    flex-direction: column;
    align-items: center;
  }

  .Potluck_quote__XKPdo {
    margin: 0;
    padding: 0;
    margin-top: 50px;
  }
  .Potluck_actively_working__2YX1p {
    flex-direction: column;
  }

  .Potluck_final_stats_inner__38VIw {
    flex-direction: column;
  }
}

.Potluck_interview_card__1Izwg {
  display: flex;
  background-color: #F0F2F6;
  padding: 40px;
  border-radius: 10px;
  align-items: flex-start;
  margin: 20px 0;
}

.Potluck_interview_card__1Izwg img {
  width: 100px;
  border-radius: 100px;
  margin-right: 40px;
}

.Potluck_goals__RS1jn {
  display: flex;
}

.Potluck_goal__m40Ff {
  background-color: #f0f2f6;
  padding: 20px;
  flex: 1 1;
  margin-top: 20px;
}

.Potluck_goal__m40Ff:nth-child(2) {
  margin: 20px 20px 0;
}

@media screen and (max-width: 422px) {
  .Potluck_sur_stats__1AWzM {
    justify-content: center;
  }
}
.utilities_d_flex__1yAyI {display: flex; }
.utilities_flex_one__32sMi {flex: 1 1}
.utilities_text_center__Ypcq1 {text-align: center;}
.utilities_text_left__33xlc {text-align: left;}
.utilities_justify_center__2mDnC {justify-content: center;}
.utilities_flex_center__16KzG {align-items: center;}
.utilities_flex_end__1VcDZ {align-items: end;}
.utilities_flex_column__3PrZu {flex-direction: column;}
.utilities_flex_wrap__L9kFd {flex-wrap: wrap;}
.utilities_button__2zUVY {
  margin: auto;
  background: #394EDD;
  color: #fff;
  border-radius: 5px;
  padding: 10px 40px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.utilities_button__2zUVY:hover {
  color: #FFF;
  text-decoration: none;
  background: #2233A7
}

.utilities_prototype__l9x6z {
  border: 5px solid #000;
  border-radius: 30px;
  margin: auto;
}

.utilities_float_left__IK9bg {float: left;}
.utilities_float_right__frUlw {float: right;}

.Walden_walden_container__3cELd {
  width: 100%;
  margin: auto;
  margin-top: 40px;
}

.Walden_container__3iRiF {
  display: flex;
}

.Walden_flex_item__2q7Ao {
  flex: 1 1;
  flex-wrap: wrap;
}

.Parsehub_container__CQO3I {
  width: 100%;
  margin: auto;
}

.Parsehub_vax_header__18-uN {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.Parsehub_persona__2uPUc {
  display: flex;
}

.Parsehub_quote__15319 {
  border-left: 3px solid #dfdfdf;
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;

  color: #999999;
  font-style: italic;
}

.Parsehub_bg__3hgb8 {
  background-color: red;
  background-image:
  radial-gradient(
    circle at top right,
    #263841,
    #0d161c 70%
  );
}

.Parsehub_demos__3AOT- {
  display: flex;
}

.Parsehub_demos__3AOT- div {
  margin: 20px;
  text-align: center;
  flex: 1 1;
}

.Parsehub_demos__3AOT- h1 {
  margin: 15px 0px;
  font-size: 1.5em;
  color: #0074ff;
  font-weight: 700;
}

.Parsehub_demos__3AOT- p {
  color: #3b474e;
  text-align: center;
  font-weight: 100;
}
.Parsehub_title__27ufA {
background-color: #0074ff;
    padding: 40px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    font-size: 2em;
    border-radius: 5px;
    margin-bottom: 50px;
}

@media screen and (max-width: 750px) {
  .Parsehub_chall__1S6mq {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 712px) {
  .Parsehub_demos__3AOT- {
    flex-direction: column;
  }
}

.Moment_container__2VsS2 {
  width: 100%;
  margin: auto;
}

.Moment_vax_header__gBltC {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.Moment_persona__1Wtmm {
  display: flex;
}

.Moment_quote__o2_FB {
  border-left: 3px solid #dfdfdf;
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;

  color: #999999;
  font-style: italic;
}

.Moment_insight__2u33i {
  background-color: #0074ff33;
  padding: 10px;
  border-radius: 20px;
  position: relative;
}

.Moment_insight_ideas__1vRAM {
  padding: 20px;
  border-radius: 20px;
  position: relative;
  box-shadow: 6px 5px 20px 0px rgb(207, 215, 230)
}

.Moment_insight_ideas__1vRAM ul li{
  list-style-position: outside;
}

.Moment_insight_ideas__1vRAM ul{
  padding-left: 15px;
}

.Moment_insight_ideas__1vRAM:nth-child(2) {
margin: 0px 20px;
}

.Moment_insight__2u33i p {
  padding-left: 50px;
  color: black
}

.Moment_title__vZ9T- {
  font-family: 'Roboto Slab';
  color: #0074ff !important;
  margin-bottom: 10;
  font-weight: 900;font-size: 20px;
}

.Moment_number__3e8Kb {
  font-family: 'Roboto Slab';
  font-size: 70px;
  color: #0074ff;
  position: absolute;
  top: 0;
  font-weight: 900;
}

.Moment_insight__2u33i:nth-child(2) {
  margin: 0 20px;
}

.Moment_changes_title__2D3Qz h4 {
  font-size: 1rem;
  color: #0074ff !important;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Roboto Slab';
  letter-spacing: 2px
}
.Moment_changes_title__2D3Qz h6 {
  margin-top: 50px;
}

.Moment_dech__EMa8Q {
  padding-left: 15px;
}

ul.Moment_dech__EMa8Q li {
  list-style-position: outside;
}




@media screen and (max-width: 1100px) {
.Moment_changes__3T9DQ {
  flex-direction: column;
}
}
@media screen and (max-width: 992px) {
  .Moment_insight_cont__-H6AY {
    flex-direction: column;
  }

  .Moment_insight__2u33i:nth-child(2) {
    margin: 20px 0px;
  }

  .Moment_ideas__vE8xa {
    flex-direction: column;
  }

  .Moment_insight_ideas__1vRAM:nth-child(2) {
    margin: 30px 0px;
  }
}
.Undraw_container__2C9S8 {
  max-width: 900px;
  width: 100%;
  margin: auto;
}

.Undraw_vax_header__GvRki {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.Undraw_persona__2IGTr {
  display: flex;
}

.Undraw_quote__ghsRr {
  border-left: 3px solid #dfdfdf;
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;

  color: #999999;
  font-style: italic;
}

.Undraw_button__3NwWs {
  padding: 10px 15px;
  margin: 2rem auto !important;
  color: #fff;
  background: #0074FF;
  border-radius: 4px;
  font-size: 14px;
  display: inline-flex;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
}

.Undraw_btn_cont___1NkS {
  width: 100%;
  text-align: center;
}

a.Undraw_button__3NwWs:hover {
  text-decoration: none;
  color: #fff;
  background-color: #0074ffa9;
}

.Schedu_container__1EMuM {
  width: 100%;
  margin: auto;
}

.Schedu_vax_header__2qxmv {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.Schedu_persona__3skbj {
  display: flex;
}

.Schedu_quote__l8v5q {
  border-left: 3px solid #dfdfdf;
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;

  color: #999999;
  font-style: italic;
}

.Schedu_insight__2AYCy {
  background-color: #0074ff33;
  padding: 10px;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 20px !important;
}

.Schedu_cara_img__2rb5H {
  box-shadow: 0 0 30px #44556622;
}
.Schedu_slider__3u3vw {
  margin-bottom: 260px;
}

.Schedu_insight_ideas__3h-vf {
  padding: 20px;
  border-radius: 20px;
  position: relative;
  box-shadow: 6px 5px 20px 0px rgb(207, 215, 230)
}

.Schedu_insight_ideas__3h-vf ul li{
  list-style-position: outside;
}

.Schedu_insight_ideas__3h-vf ul{
  padding-left: 15px;
}

.Schedu_insight__2AYCy p {
  color: rgb(41, 61, 90);
  margin: 20px;
}

.Schedu_title__kGC_S {
  font-family: 'Roboto Slab';
  color: #0074ff !important;
  margin-bottom: 10;
  font-weight: 900;font-size: 20px;
}

.Schedu_number__I8OaF {
  font-family: 'Roboto Slab';
  font-size: 70px;
  color: #0074ff;
  position: absolute;
  top: 0;
  font-weight: 900;
}

.Schedu_changes_title__u88sd h4 {
  font-size: 1rem;
  color: #0074ff !important;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Roboto Slab';
  letter-spacing: 2px
}
.Schedu_changes_title__u88sd h3 {
  font-size: 1.5rem;
}

.Schedu_dech__2z1r9 {
  padding-left: 15px;
}

ul.Schedu_dech__2z1r9 li {
  list-style-position: outside;
}

.Schedu_research__20dQB {
  text-align: left;
  font-weight: 500;
  line-height: 1.4rem;
}

.Schedu_flex_one__3nlZv {
  margin: 0 20px;
}

.Schedu_stat__1v-ru {
  color: rgb(32, 42, 56);;
  font-size: 60px;
  font-weight: 600;
  text-align: left;
}

.Schedu_idea__2S65- {
  flex-direction: column;
  flex: 0 0 45%;
  background-color: #0074ff33;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  display: flex;
  margin: 20px !important;
}

.Schedu_insight_cont__3qMwK {
  flex-wrap: wrap;
}

.Schedu_moving__25mGN {
  position: fixed;
  top: 200px;
  left: 0px;
  list-style: none;
  color: #173355;
  flex-direction: column
}

.Schedu_moving__25mGN a {
  color: #173355;
}

.Schedu_moving__25mGN a:hover {
  text-decoration: none;
  color: #0074ff;
}

.Schedu_moving__25mGN li {
  padding: 3px 0;
  font-size: 13px;
}

.Schedu_moving__25mGN ul {
  list-style: none;
}

.Schedu_active__AaAaY a{
  border-left: 3px solid #0074ff;
  padding-left: 20px;
  color: #0074ff;
}

@media screen and (max-width: 1000px) {
  .Schedu_insight_cont__3qMwK {
    flex-direction: column;
  }
}

@media screen and (max-width: 774px) {
  .Schedu_moving__25mGN {
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    font-size: 16px;
    display: none;
    box-shadow: 0px 0px 20px #00000022;
  }

  .Schedu_moving__25mGN ul {
    padding-left: 10px;
    display: flex;
    margin: 0px;
  }

  .Schedu_moving__25mGN li {
    display: inline-block;
    padding: 15px;
    font-size: 16px;
  }

  .Schedu_moving__25mGN .Schedu_active__AaAaY a {
    border-bottom: 2px solid #3549e6;
    border-left: 0;
    padding: 0;
  }

  .Schedu_moving__25mGN::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
    display: none;
}
  /* Optional: show position indicator in red */
  .Schedu_moving__25mGN::-webkit-scrollbar-thumb {
      background: transparent;
      width: 1;
  }
}

@media screen and (max-width: 550px) {
  .Schedu_videos__3QnIP {
    flex-direction: column;
  }
}

@media screen and (max-width: 530px) {
  .Schedu_nums__3zWcN {
    flex-direction: column;
  }
}




.Instura_container__mPd13 {
  width: 100%;
  margin: auto;
}

.Instura_context__1HH_w{
  display: flex;
}

.Instura_container__mPd13 h4 {
  font-weight: 100 !important;
  line-height: 38px;
  font-size: 20px;
}

.Instura_context_inner__YOY-Y {
  margin-right: 50px;
}

.Instura_bg__3GFH1 {
  width: 120%;
  position: absolute;
  bottom: 0px;
  right: -35%;
}
.Instura_vax_header__3MC-8 {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.Instura_person__3yZ23 {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  border: 5px solid #fff;
}

.Instura_interview__3l739 {
  background: #dfe4ec;
  padding-bottom: 5rem;
}

.Instura_hmw__KTwPB {
  background: #f5f7fb;
  margin-top: 40px;
  padding: 100px 0px;
  align-items: center;
  display: flex;
  align-items: center;
  position: relative;
}

.Instura_phone_gif__3VX-g {
  border-radius: 20px;
  border: 4px solid #15395d;
  width: 250px;
  box-shadow: 4px 4px 30px #aeb4b9;
}

.Instura_web_gif__3Ul3e {
  border-radius: 20px;
  border: 4px solid #15395d;
  width: 100%;
  box-shadow: 4px 4px 30px #aeb4b9;
}

.Instura_text__VdD6y {
  max-width: 600px;
  width: 100%;
}

.Instura_fin_text__3JBAP:nth-child(even) .Instura_text__VdD6y{
  margin-left: 40px;
}
.Instura_fin_text__3JBAP:nth-child(odd) .Instura_text__VdD6y {
  padding-right: 40px;
}

.Instura_text_section__2YoVc {
  margin: 7rem auto;
}

.Instura_final__1jb-N {
  background: #F0F2F6;
  padding: 100px 0px;
}

.Instura_table__3ciWw table {
  width: auto;
}

.Instura_section_margin__2a6lK {
  margin-top: 150px;
  margin-bottom: 150px;
}

.Instura_table__3ciWw td {
  text-align: center;
  font-size: 14px;
  padding: 5px;
  display: table-cell;
  width: 2%;
}
.Instura_table__3ciWw th {
  text-align: center;
  font-size: 14px;
  padding: 5px;
  display: table-cell;
  width: 2%;
}

.Instura_lofi__1VGnu {
  background: #e5e5e5;
  padding: 5rem 0;
  margin-top: 40px;
  align-items: center;
  display: flex;
  align-items: center;
  position: relative;
}

.Instura_flower__3kmrO {
  position: absolute;
  width: 200px;
  right: 0;
  bottom: -10px;
  z-index: -1;
}

.Instura_hmw__KTwPB h1 {
  widows: 100%;
  align-self: flex-start;
}

.Instura_sketches__3Oxmx {
  display: flex;
  margin-top: 40px;
  align-items: center;
}
.Instura_persona__WC44N {
  display: flex;
}

.Instura_quote__36VoG {
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;
  font-family: 'Sofia Pro';
  color: #535b6d;
  font-style: italic;
}

.Instura_insight__De6QL {
  padding: 10px;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: left;
  font-weight: 500;
  font-size: 20px;
  font-weight: 600;
  flex: 1 1;
}

.Instura_cara_img__CqlMe {
  box-shadow: 0 0 30px #44556622;
}

.Instura_slider__DDVgW {
  margin-bottom: 260px;
}

.Instura_insight_ideas__19eL5 {
  padding: 20px;
  border-radius: 20px;
  position: relative;
  box-shadow: 6px 5px 20px 0px rgb(207, 215, 230)
}

.Instura_insight_ideas__19eL5 ul li{
  list-style-position: outside;
}

.Instura_insight_ideas__19eL5 ul{
  padding-left: 15px;
}

.Instura_insight_ideas__19eL5:nth-child(2) {
margin: 0px 20px;
}

.Instura_insight__De6QL p {
  color: rgb(41, 61, 90);
  font-weight: 400;
}

.Instura_title__1FqY8 {
  font-family: 'Roboto Slab';
  color: #0074ff !important;
  margin-bottom: 10;
  font-weight: 900;font-size: 20px;
}

.Instura_number__1F82Y {
  font-family: 'Roboto Slab';
  font-size: 70px;
  color: #0074ff;
  position: absolute;
  top: 0;
  font-weight: 900;
}

.Instura_insight__De6QL:nth-child(even) {
  margin: 0 20px;
}

.Instura_changes_title__1a3Gu h4 {
  font-size: 1rem;
  color: #0074ff !important;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Roboto Slab';
  letter-spacing: 2px
}
.Instura_changes_title__1a3Gu h3 {
  font-size: 1.5rem;
}

.Instura_dech__3r-FF {
  padding-left: 15px;
}

ul.Instura_dech__3r-FF li {
  list-style-position: outside;
}

.Instura_research__MdVDI {
  text-align: left;
  font-weight: 500;
  line-height: 1.4rem;
  width: 200px;
}

.Instura_flex_one__2znLV {
  margin: 0 20px;
  flex: 0 0 20%;
}

.Instura_stat__1QcdF {
  color: rgb(32, 42, 56);
  font-size: 60px;
  font-weight: 600;
  text-align: left;
}

.Instura_sus__1kasG {
  background-color: #f5f7fb;
  padding: 5rem 0;
  display: flex;
  align-items: center;


}

.Instura_stats_list__3ud0h {
  margin-bottom: 100px;
}

.Instura_idea__2JTTl {
  flex-direction: column;
  flex: 0 0 46%;
  background-color: #0074ff33;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  display: flex;
  margin: 20px !important;
}

.Instura_insight_cont__3UsaS {
  flex-wrap: wrap;
}

.Instura_lofi_img__IFdRO {
  width: 70%;
  margin-right: -200;
  margin-left: 40;
}

.Instura_reflect__1iLxs {
  background-color: #f5f7fb;
  padding: 5rem 0px;
}

.Instura_reflect_text__1QJOC {
  margin-right: 40px;
}

.Instura_bar_graph__10KXz {
  width: 45%;
  margin-top: 50px
}

.Instura_bar_graph_2__3qaUs {
  width: 55%;
  margin-top: 50px
}

.Instura_concept_feedback__hSHbD {
  display: flex;
  flex-wrap: wrap;
}

.Instura_concept_feedback__hSHbD p {
  width: 100%;
  margin-right: 20px;
  max-width: 47%;
  margin-bottom: 30px;
  font-style: italic;
  font-family: "Sofia Pro";
  background-color: #f5f7fb;
  padding: 20px;
  border-radius: 20px;
}

.Instura_ob_images__22vJW {
  margin: 3rem auto;
}

.Instura_observations__2dook {
  background-color: #f5f7fb;
}

.Instura_observe__ZuXIn {
  width: 200px;
  height: auto;
  margin-right: 50px;
}

@media screen and (max-width: 1251px) {
  .Instura_sur_stats__2lpiS {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1111px) {
.Instura_concept_feedback__hSHbD p {
  max-width: 100%;
  margin-right: 0;

}
}

@media screen and (max-width: 1000px) {
  .Instura_text_section__2YoVc:nth-child(odd) div:nth-child(2) {
    margin-right: -100px;
    
  }
}
@media screen and (max-width: 870px) {
 .Instura_bar_graph__10KXz, .Instura_bar_graph_2__3qaUs {
   width: 100%;
 }
}
@media screen and (max-width: 800px) {
  .Instura_fin_text__3JBAP {
    flex-direction: column;
    align-items: baseline;
  }

  .Instura_fin_text__3JBAP:nth-child(even) .Instura_text__VdD6y {
    margin: 0;
  }

  .Instura_fin_text__3JBAP:nth-child(odd) .Instura_fin_text_first__FXwAx {
    order: 1;
  }

  .Instura_fin_text__3JBAP .Instura_text__VdD6y {
    padding-right: 0 !important;
    margin-top: 50px !important;
  }

  .Instura_fin_text__3JBAP:nth-child(odd) div:nth-child(2) {
    margin-right: 0px;
    
  }
}
@media screen and (max-width: 774px) {
  .Instura_final__1jb-N {
  }

  .Instura_hmw__KTwPB {
    z-index: 1;
  }

  .Instura_sketches__3Oxmx {
    flex-direction: column;
  }

  .Instura_lofi__1VGnu {
    flex-direction: column;
  }

  .Instura_lofi_img__IFdRO {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
}

  .Instura_research__MdVDI {
    width: 150px;
  }
}

.Instura_progress__1Hfri {
  width: 100%;
  height: 5px;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 999999999999999999999999999999;
}

.Instura_progress_inner__2ozZM {
  background-color: #0074ff;
  height: 100%;
  /* transition: all 0.3s ease; */
}

@media screen and (max-width: 700px) {
  .Instura_reflect__1iLxs div {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .Instura_reflect_text__1QJOC {
    margin-right: 0;
  }
  
}

@media screen and (max-width: 774px) {
  .Instura_web_gif__3Ul3e {
    width: 100%;
  }
  .Instura_context__1HH_w {
    flex-direction: column;
  }

  .Instura_context_inner__YOY-Y {
    margin: 0;
    margin-bottom: 40px;
  }
  
}
@media screen and (max-width: 600px) {
  .Instura_insight_cont__3UsaS {
    flex-direction: column;
  }

  .Instura_card__1D0f5 {
    flex-direction: column;
    margin: 50px 0px;
    text-align: center;
  }

  .Instura_insight__De6QL:nth-child(2) {
    margin: 20px 0px;
  }

  .Instura_ob_images__22vJW {
    flex-direction: column;
  }

  .Instura_ob_images__22vJW p {
    margin-top: 20px;
  }

  .Instura_observe__ZuXIn {
    width: 100%;
  }
}

@media screen and (max-width: 578px) {
  .Instura_interview_basic__PnfJO {
    flex-direction: column;
    align-items: center;
  }

  .Instura_quote__36VoG {
    margin: 0;
    padding: 0;
    margin-top: 50px;
  }
}
.About_container__i_9IO {
  max-width: 1100px;
  width: 100%;
  margin: 5rem auto;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  flex-direction: column;
}
.About_about_container__3jvXl {
  display: flex;
  padding: 40px;
  padding-bottom:0px ;
}

.About_about_container__3jvXl h1 {
  margin-bottom: 10px;
}

.About_picture__3247A {
  max-width: 200px;
  width: 50vw;
  border-radius: 100px;
  margin-right: 50px;
  margin-bottom: 40px;
}

.About_container__i_9IO a {
  font-weight: 600;
}

.About_about_container__3jvXl h5 {
  text-transform: none;
  color: #303A49;
  letter-spacing: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 30px;
}

.About_about_container__3jvXl h5 b {
  text-transform: none;
  color: #3a4fdd;
  letter-spacing: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 30px;
}

.About_fun_img__11OHU {
  width: 20%;
  border-radius: 10px;
  margin: 10px;

}

.About_achi__1xrp_ {
  border-left: 1px solid #627180;
  padding-left: 10px;
  margin: 10px 0px;
}

.About_achi__1xrp_ p {
  margin: 0px;
  font-size: 14px;
  color: #626b74;
}

.About_fun_facts__kq85B div {
  flex: 1 1;
}

.About_fun_facts__kq85B {
  margin: 1.8rem 25%;
  display: flex;

}

.About_achievement_cont__dt05A {
  display: flex;
  flex-direction: column;
}

.About_about_container__3jvXl i {
  margin: 10px;
}

.About_hp1__Aspjb {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 200px;
  z-index: -1;
}

.About_hp2__GgzBQ {
  position: absolute;
  top: 10%;
  left: 0;
  max-width: 200px;
  z-index: -1;
}

@media screen and (max-width: 1271px) {
  .About_hp1__Aspjb, .About_hp2__GgzBQ {
    max-width: 150px;
  }
}
@media screen and (max-width: 1071px) {
  .About_hp1__Aspjb, .About_hp2__GgzBQ {
    max-width: 100px;
  }
}


@media screen and (max-width: 980px) {
  .About_about_container__3jvXl {
    flex-direction: column;
  }

  .About_about_container__3jvXl h4 {
    display: none;
  }

  .About_about_container__3jvXl:nth-child(1), .About_about_container__3jvXl:nth-child(2) {
    text-align: center;
  }

  .About_achievement_cont__dt05A {
    display: none
  }

  .About_picture__3247A {
    margin: 0;
  }
}

@media screen and (max-width: 518px) {
  .About_about_container__3jvXl {
    padding: 10px;
  }

  .About_picture__3247A {
    width: 75%;
  }
}

.NotFound_container__3XESE {
  max-width: 1400px;
  width: 100%;
  margin: 5rem auto;
  display: flex;
  align-items: center;
  height: 70vh;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
}

.NotFound_container__3XESE img {
  width: 100%;
  max-width: 700px;
  margin-bottom: 40px;
}


.NotFound_container__3XESE h1 {
  text-transform: none;
  font-size: 2.5rem;
  font-weight: 200;
  color: #3b4751;
  text-align: center;
}
.WebDesign_project_container__SHjR1 {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
