.container {
  max-width: 1400px;
  width: 100%;
  margin: 5rem auto;
  display: flex;
  align-items: center;
  height: 70vh;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
}

.container img {
  width: 100%;
  max-width: 700px;
  margin-bottom: 40px;
}


.container h1 {
  text-transform: none;
  font-size: 2.5rem;
  font-weight: 200;
  color: #3b4751;
  text-align: center;
}