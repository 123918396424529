@value grey from './common/css/common.module.css';
@value blue from './common/css/common.module.css';

.footer {
    background: rgb(34, 39, 46);
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.card {
    display: flex;
    border-radius: 0px;
    padding: 20px;
    height: 250px;
    text-align: center;
    flex-grow: 1;
    margin: 15px;
    min-width: 300px;
    flex-direction: column;
    transition: all 0.3s ease;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
}

.card:last-child {
    flex-grow: 0;
    width: auto
}

.card img {
    margin: auto;
}

.card:hover {
    transform: scale(1.1);
    opacity: 1;
}

.title {
    margin: 30px;
    margin-top: auto;
}

.title h4 {
    font-size: 16px;
    font-family: 'basic-sans', sans-serif;
    margin-top: 30px;
    color: rgb(23, 27, 41);
}
.title h6 {
    font-size: 12px;
    font-family: 'basic-sans', sans-serif;
    color: rgb(56, 59, 73);
}

.more_projects {
    background-color: #eef4ff;
    padding: 100px 20px;
    margin-top: 100px;
}

.more_projects_inner {
    max-width: 1100px;
    margin: auto;
}

.container {
    text-align: center
}

.contain {
    display: flex;
    flex-wrap: wrap;
}

.container i{
    margin: 40px;
    color: #fff8;
    transition: all 300ms ease-in-out;
}

.container i:hover{
    color: #fff;
    transform: scale(1.2)
}

.footer p {
    font-weight: 100;
    color: #fff8;
    font-family: 'Roboto';
    font-size: 14px;
}

@media screen and (max-width: 774px) {
    .more_projects {
        padding: 5rem 10%;
    }

}

@media screen and (max-width:  474px) {
    .card {
        margin: 15px 0;
        min-width: 200px;
    }

}