.project_container {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.project_overview {
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;
  
}