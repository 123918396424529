.container {
  width: 100%;
  margin: auto;
}

.vax_header {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.persona {
  display: flex;
}

.quote {
  border-left: 3px solid #dfdfdf;
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;

  color: #999999;
  font-style: italic;
}

.insight {
  background-color: #0074ff33;
  padding: 10px;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 20px !important;
}

.cara_img {
  box-shadow: 0 0 30px #44556622;
}
.slider {
  margin-bottom: 260px;
}

.insight_ideas {
  padding: 20px;
  border-radius: 20px;
  position: relative;
  box-shadow: 6px 5px 20px 0px rgb(207, 215, 230)
}

.insight_ideas ul li{
  list-style-position: outside;
}

.insight_ideas ul{
  padding-left: 15px;
}

.insight p {
  color: rgb(41, 61, 90);
  margin: 20px;
}

.title {
  font-family: 'Roboto Slab';
  color: #0074ff !important;
  margin-bottom: 10;
  font-weight: 900;font-size: 20px;
}

.number {
  font-family: 'Roboto Slab';
  font-size: 70px;
  color: #0074ff;
  position: absolute;
  top: 0;
  font-weight: 900;
}

.changes_title h4 {
  font-size: 1rem;
  color: #0074ff !important;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Roboto Slab';
  letter-spacing: 2px
}
.changes_title h3 {
  font-size: 1.5rem;
}

.dech {
  padding-left: 15px;
}

ul.dech li {
  list-style-position: outside;
}

.research {
  text-align: left;
  font-weight: 500;
  line-height: 1.4rem;
}

.flex_one {
  margin: 0 20px;
}

.stat {
  color: rgb(32, 42, 56);;
  font-size: 60px;
  font-weight: 600;
  text-align: left;
}

.idea {
  flex-direction: column;
  flex: 0 0 45%;
  background-color: #0074ff33;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  display: flex;
  margin: 20px !important;
}

.insight_cont {
  flex-wrap: wrap;
}

.moving {
  position: fixed;
  top: 200px;
  left: 0px;
  list-style: none;
  color: #173355;
  flex-direction: column
}

.moving a {
  color: #173355;
}

.moving a:hover {
  text-decoration: none;
  color: #0074ff;
}

.moving li {
  padding: 3px 0;
  font-size: 13px;
}

.moving ul {
  list-style: none;
}

.active a{
  border-left: 3px solid #0074ff;
  padding-left: 20px;
  color: #0074ff;
}

@media screen and (max-width: 1000px) {
  .insight_cont {
    flex-direction: column;
  }
}

@media screen and (max-width: 774px) {
  .moving {
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    font-size: 16px;
    display: none;
    box-shadow: 0px 0px 20px #00000022;
  }

  .moving ul {
    padding-left: 10px;
    display: flex;
    margin: 0px;
  }

  .moving li {
    display: inline-block;
    padding: 15px;
    font-size: 16px;
  }

  .moving .active a {
    border-bottom: 2px solid #3549e6;
    border-left: 0;
    padding: 0;
  }

  .moving::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
    display: none;
}
  /* Optional: show position indicator in red */
  .moving::-webkit-scrollbar-thumb {
      background: transparent;
      width: 1;
  }
}

@media screen and (max-width: 550px) {
  .videos {
    flex-direction: column;
  }
}

@media screen and (max-width: 530px) {
  .nums {
    flex-direction: column;
  }
}