.title_container {
  margin: 20px 0px;
}


.title_container_inner {
  margin: auto;
  display: flex;
}

.title_container_inner h5 {
    margin-top: 20px;
    color: #3f4d5b;
    text-transform: uppercase;
    font-family: 'basic-sans', sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 14px;
}

.title_quote h2 {
    font-size: 30px;
    line-height: 1.5;
    color: #293846;
    margin-bottom: 10px;
    font-family: 'basic-sans', sans-serif;
    font-weight: 500;
    text-transform: none;
    position: relative;
}

.title_quote h2:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 1px;
    bottom: -10px;
    left: 0;
    border-bottom: 2px solid #364ae6;
}

.accent {
  border-radius: 90px;
  border-top-right-radius: 0px;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #0D1533;
  width: 60px;
  height: 60px;
  line-height: 50px;
}

@media screen and (max-width: 490px) {
  .accent {
    display: none
  }

  .title_quote {
    margin-left: 0px;
  }
}