.project_container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.img  {
  width: 30%;
  margin: 10px;
  border-radius: 10px;
}

@media screen and (max-width: 640px) {
  .img {
    width: 100%;
  }

}