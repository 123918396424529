
.tools {
  text-align: left;
  margin: 3rem auto;
}

.info {
  border-bottom: 1px solid #d7dae2;
  padding-bottom: 40px;
  margin-top: 0px;
  padding-top: 0px;

}

.tool_icon {
  width: 25px;
  margin-right: 10px;
}

.info h5  {
  color: #1D222F;
  font-weight: 700;
  font-size: 1.1rem !important;
  letter-spacing: normal;
  margin-bottom: 10px;
}

.tools div {
  flex: 1;
  
}

.tools h2, .prob_sol h2 {
    font-size: 25px;
    line-height: 1.5;
    color: #293846;
    margin-bottom: 10px;
    font-family: 'Sofia Pro';
    text-transform: none;
}
.prob_sol {
  display: flex;
}

.prob_sol div {
  flex: 1;
}

.tools {
  display: flex;
}
.tools div {
  flex: 1;
}

.prob_sol div:nth-child(1) {
  padding-right: 20px;
}

.quick_info div {
  flex: 1;
  text-align: center;

}

.quick_info {
  margin: auto;
  display: flex;
  flex-direction: row;

}

.quick_info h1 {
  margin: 0;
    display: inline-block;
    font-size: 1rem !important;
    font-weight: 600;
    text-transform: uppercase;
    color: #1D222F;
    margin-bottom: 20px;
    border-bottom: 3px solid #6b69ea;
    padding-bottom: 10px;
}

.quick_info h5 {
  font-family: 'sofia-pro';
  font-weight: 700;
  color: rgb(49, 57, 77);
  font-size: 16px;
  border-bottom: 1px solid orange;
  padding-bottom: 10px;
  margin: auto;
  width: fit-content;
}


.tools span::after {
  content: ', '
}

@media screen and (max-width: 774px) {
  .tools {
    flex-direction: column;
  }

}

@media screen and (max-width: 587px) {
  .quick_info {
    flex-direction: column;
  }
}
@media screen and (max-width: 460px) {
  .prob_sol {
    flex-direction: column;
  }
  .prob_sol div:nth-child(1) {
    padding: 0;
  }
  .prob_sol div:nth-child(2) {
    margin-top: 3rem;
  }
}