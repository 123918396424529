.project_container {
  max-width: 1200px;
  margin: 30px auto;
  display: flex;
}

.project, .project2 {
  background: #FFD75C;
  border-radius: 10px;
  flex: 1;
  position: relative;
  max-height: 500px;
  min-height: 500px;
  transition: 0.3s ease;
  flex-grow: 1;
  border: 1px solid #E3E3E3;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.project_old{
  background: #FFD75C;
  border-radius: 10px;
  flex: 1;
  position: relative;
  max-height: 500px;
  min-height: 500px;
  transition: 0.3s ease;
  flex-grow: 1;
  align-items: center;
  border: 1px solid #E3E3E3;
  overflow: hidden;
  display: flex;
  justify-content: center;
}


.image {
  max-height: 400px;
  height: initial;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top: none;
  filter: url('#shadowed-goo');

}

.used {
  position: absolute;
  background: #fff;
  right: 20px;
  padding: 20px;
  border-radius: 120px;
  top: -40px;
}

.info h3 {
  color: #14234A;
  margin: 0;
  font-weight: 500;
}

.info p {
  font-size: 14px;
  margin: 0;
  line-height: 1.5rem;
  color: #424d58;
  margin-top: 10px;
  
}

.project_hover {
  background: rgba(42, 50, 63, 0.8);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.project_old h3 {
  font-family: Sofia Pro, sans-serif;
  font-weight: 600px;
}

.project_old h6 {
  font-family: Sofia Pro, sans-serif;
}

.hp1 {
  position: absolute;
  right: 0;
  top: -50px;
  max-width: 250px

}

.hp2 {
  position: absolute;
  left: 0;
  top: 10%;
  max-width: 270px;
}

.project_hover h3 {
  color: white;
  text-align: center;
  font-family: 'Roboto Slab'
}

.project_hover div {
  width: 100%;
  text-align: center;
}

.project_old {
  flex-direction: column;
  color: #14234A;
}

.project_old:hover {
  flex-direction: column;
  color: #14234A;
}

.project_hover p {
  color: white
}
/* 
.project:hover .project_hover, .project2:hover .project_hover {
  opacity: 1;
} */

.project:hover, .project_old:hover {
  transform: translateY(-10px);
  box-shadow: 5px 17px 40px -10px #bcbfce;

  text-decoration: none;
}

.project, .project_old {
  flex: 0 47% !important;
  margin: 15px;
  flex-grow: 1;
}

.project img, .project2 img {
  margin: auto;
}

.content {
  margin-top: 100px;
}


@media screen and (max-width: 1000px) {
  .project_container {
    flex-direction: column;
  }
  .project_container a {
    margin: 40px;
  }

  .project {
    height: auto;
    flex: 1 100% !important;
  }
}

@media screen and (max-width: 663px) {
  .width.project img {
    height: auto !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 620px) {
  .text_bg {
    font-size: 30px;
  }
}
@media screen and (max-width: 466px) {
  .info {
    flex-direction: column;
    text-align: center;
  }
  .text_bg {
    font-size: 30px;
    border: 0;
    border-top: 6px solid orange;
    padding: 20px;
  }

  .top_logo {
    margin: auto;
  }
}