.progress {
  width: 100%;
  height: 6px;
  position: fixed;
  top: 0;
  z-index: 2147483638;
}

.progress_inner {
  background-color: #3549e6;
  height: 100%;
}
