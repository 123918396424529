.container {
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;
  font-size: 16px;
  padding: 20px;
  color: rgb(29, 36, 63);
}

.container b {
  background: transparent;
  color: #384EDD;
  font-weight: 700;
  padding: 0;
}

.title_container {
  background-color: #F0F4F8;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 50px;
}

.title_container_inner {
  max-width: 80%;
  margin: auto;
  display: flex;
}


.hp1 {
  position: absolute;
  right: 0;
  top: -50px;
  z-index: -1;
  max-width: 150px

}

.hp2 {
  position: absolute;
  left: 0;
  top: 20%;
  z-index: -1;
  max-width: 150px;
}

.title_container_inner h5 {
  color: #384EDD;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  letter-spacing: 2px;
}

.quote {
  font-size: 35px;
  font-weight: 600;
  line-height: 50px;
  margin-left: 20px;
}

.accent {
  border-radius: 90px;
  border-top-right-radius: 0px;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #0D1533;
  width: 70px;
  height: 70px;
  line-height: 50px;
}

.concept_container {
  display: flex;
  margin-top: 50px;
}

.concept_container div {
  flex: 1;
}

.concept_container div:nth-child(2) {
  margin: 0px 70px
}

.container h4 {
  font-size: 19px;
  margin: 5px 0px;
  font-weight: 700;
}

.container hr {
  margin: 50px 0;
}

.process_container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 100px auto;

}

.process_container div {
  flex: 1;
  min-width: 44%;
  margin-bottom: 20px;
}

.process_container div:nth-child(odd) {
  margin-right: 40px;
}

@media screen and (max-width: 1416px) {
  .hp1, .hp2 {
    display: none;
  }

}

@media screen and (max-width: 740px) {
  .title_container_inner {
    max-width: 100%;
  }

}


@media screen and (max-width: 645px) {
  .concept_container {
    flex-direction: column;
  }

  .concept_container div:nth-child(2) {
    margin: 20px 0px
}

}
@media screen and (max-width: 515px) {
  .process_container {
    flex-direction: column;
  }

  .quote {
    font-size: 25px;
    line-height: 35px;
  }
}
@media screen and (max-width: 445px) {
  .accent {
    width: 50px;
    height: 50px;
    padding: 20px;
  }

  .title_container, .title_container_inner {
    flex-direction: column;
  }

  .quote {
    margin: 20px 0px 0px 0px;
  }
}