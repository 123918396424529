.container {
  width: 100%;
  margin: auto;
}

.context{
  display: flex;
}

.container h4 {
  font-weight: 100 !important;
  line-height: 38px;
  font-size: 20px;
}

.context_inner {
  margin-right: 50px;
}

.bg {
  width: 120%;
  position: absolute;
  bottom: 0px;
  right: -35%;
}
.vax_header {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.person {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  border: 5px solid #fff;
}

.interview {
  background: #dfe4ec;
  padding-bottom: 5rem;
}

.hmw {
  background: #f5f7fb;
  margin-top: 40px;
  padding: 100px 0px;
  align-items: center;
  display: flex;
  align-items: center;
  position: relative;
}

.phone_gif {
  border-radius: 20px;
  border: 4px solid #15395d;
  width: 250px;
  box-shadow: 4px 4px 30px #aeb4b9;
}

.web_gif {
  border-radius: 20px;
  border: 4px solid #15395d;
  width: 100%;
  box-shadow: 4px 4px 30px #aeb4b9;
}

.text {
  max-width: 600px;
  width: 100%;
}

.fin_text:nth-child(even) .text{
  margin-left: 40px;
}
.fin_text:nth-child(odd) .text {
  padding-right: 40px;
}

.text_section {
  margin: 7rem auto;
}

.final {
  background: #F0F2F6;
  padding: 100px 0px;
}

.table table {
  width: auto;
}

.section_margin {
  margin-top: 150px;
  margin-bottom: 150px;
}

.table td {
  text-align: center;
  font-size: 14px;
  padding: 5px;
  display: table-cell;
  width: 2%;
}
.table th {
  text-align: center;
  font-size: 14px;
  padding: 5px;
  display: table-cell;
  width: 2%;
}

.lofi {
  background: #e5e5e5;
  padding: 5rem 0;
  margin-top: 40px;
  align-items: center;
  display: flex;
  align-items: center;
  position: relative;
}

.flower {
  position: absolute;
  width: 200px;
  right: 0;
  bottom: -10px;
  z-index: -1;
}

.hmw h1 {
  widows: 100%;
  align-self: flex-start;
}

.sketches {
  display: flex;
  margin-top: 40px;
  align-items: center;
}
.persona {
  display: flex;
}

.quote {
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;
  font-family: 'Sofia Pro';
  color: #535b6d;
  font-style: italic;
}

.insight {
  padding: 10px;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: left;
  font-weight: 500;
  font-size: 20px;
  font-weight: 600;
  flex: 1;
}

.cara_img {
  box-shadow: 0 0 30px #44556622;
}

.slider {
  margin-bottom: 260px;
}

.insight_ideas {
  padding: 20px;
  border-radius: 20px;
  position: relative;
  box-shadow: 6px 5px 20px 0px rgb(207, 215, 230)
}

.insight_ideas ul li{
  list-style-position: outside;
}

.insight_ideas ul{
  padding-left: 15px;
}

.insight_ideas:nth-child(2) {
margin: 0px 20px;
}

.insight p {
  color: rgb(41, 61, 90);
  font-weight: 400;
}

.title {
  font-family: 'Roboto Slab';
  color: #0074ff !important;
  margin-bottom: 10;
  font-weight: 900;font-size: 20px;
}

.number {
  font-family: 'Roboto Slab';
  font-size: 70px;
  color: #0074ff;
  position: absolute;
  top: 0;
  font-weight: 900;
}

.insight:nth-child(even) {
  margin: 0 20px;
}

.changes_title h4 {
  font-size: 1rem;
  color: #0074ff !important;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Roboto Slab';
  letter-spacing: 2px
}
.changes_title h3 {
  font-size: 1.5rem;
}

.dech {
  padding-left: 15px;
}

ul.dech li {
  list-style-position: outside;
}

.research {
  text-align: left;
  font-weight: 500;
  line-height: 1.4rem;
  width: 200px;
}

.flex_one {
  margin: 0 20px;
  flex: 0 0 20%;
}

.stat {
  color: rgb(32, 42, 56);
  font-size: 60px;
  font-weight: 600;
  text-align: left;
}

.sus {
  background-color: #f5f7fb;
  padding: 5rem 0;
  display: flex;
  align-items: center;


}

.stats_list {
  margin-bottom: 100px;
}

.idea {
  flex-direction: column;
  flex: 0 0 46%;
  background-color: #0074ff33;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  display: flex;
  margin: 20px !important;
}

.insight_cont {
  flex-wrap: wrap;
}

.lofi_img {
  width: 70%;
  margin-right: -200;
  margin-left: 40;
}

.reflect {
  background-color: #f5f7fb;
  padding: 5rem 0px;
}

.reflect_text {
  margin-right: 40px;
}

.bar_graph {
  width: 45%;
  margin-top: 50px
}

.bar_graph_2 {
  width: 55%;
  margin-top: 50px
}

.concept_feedback {
  display: flex;
  flex-wrap: wrap;
}

.concept_feedback p {
  width: 100%;
  margin-right: 20px;
  max-width: 47%;
  margin-bottom: 30px;
  font-style: italic;
  font-family: "Sofia Pro";
  background-color: #f5f7fb;
  padding: 20px;
  border-radius: 20px;
}

.ob_images {
  margin: 3rem auto;
}

.observations {
  background-color: #f5f7fb;
}

.observe {
  width: 200px;
  height: auto;
  margin-right: 50px;
}

@media screen and (max-width: 1251px) {
  .sur_stats {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1111px) {
.concept_feedback p {
  max-width: 100%;
  margin-right: 0;

}
}

@media screen and (max-width: 1000px) {
  .text_section:nth-child(odd) div:nth-child(2) {
    margin-right: -100px;
    
  }
}
@media screen and (max-width: 870px) {
 .bar_graph, .bar_graph_2 {
   width: 100%;
 }
}
@media screen and (max-width: 800px) {
  .fin_text {
    flex-direction: column;
    align-items: baseline;
  }

  .fin_text:nth-child(even) .text {
    margin: 0;
  }

  .fin_text:nth-child(odd) .fin_text_first {
    order: 1;
  }

  .fin_text .text {
    padding-right: 0 !important;
    margin-top: 50px !important;
  }

  .fin_text:nth-child(odd) div:nth-child(2) {
    margin-right: 0px;
    
  }
}
@media screen and (max-width: 774px) {
  .final {
  }

  .hmw {
    z-index: 1;
  }

  .sketches {
    flex-direction: column;
  }

  .lofi {
    flex-direction: column;
  }

  .lofi_img {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
}

  .research {
    width: 150px;
  }
}

.progress {
  width: 100%;
  height: 5px;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 999999999999999999999999999999;
}

.progress_inner {
  background-color: #0074ff;
  height: 100%;
  /* transition: all 0.3s ease; */
}

@media screen and (max-width: 700px) {
  .reflect div {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .reflect_text {
    margin-right: 0;
  }
  
}

@media screen and (max-width: 774px) {
  .web_gif {
    width: 100%;
  }
  .context {
    flex-direction: column;
  }

  .context_inner {
    margin: 0;
    margin-bottom: 40px;
  }
  
}
@media screen and (max-width: 600px) {
  .insight_cont {
    flex-direction: column;
  }

  .card {
    flex-direction: column;
    margin: 50px 0px;
    text-align: center;
  }

  .insight:nth-child(2) {
    margin: 20px 0px;
  }

  .ob_images {
    flex-direction: column;
  }

  .ob_images p {
    margin-top: 20px;
  }

  .observe {
    width: 100%;
  }
}

@media screen and (max-width: 578px) {
  .interview_basic {
    flex-direction: column;
    align-items: center;
  }

  .quote {
    margin: 0;
    padding: 0;
    margin-top: 50px;
  }
}