.menu {
   margin-top: -65px;
}
 .menu-item {
	 background: #fff;
	 border-radius: 100%;
	 width: 50px;
	 height: 50px;
	 position: absolute;
	 top: 20px;
	 color: #000;
	 text-align: center;
	 transform: translate3d(0, 0, 0);
   transition: transform ease-out 200ms;
   display: flex;
}
 .menu-open {
	 display: none;
}
 .menu {
	 position: absolute;
	 right: 70px;
	 width: auto;
	 box-sizing: border-box;
	 font-size: 20px;
	 text-align: left;
}
 .menu-item {
	 overflow: hidden;
}
 .menu-item:after {
	 content: "";
	 display: block;
	 position: absolute;
	 width: 100%;
	 height: 100%;
	 top: 0;
	 left: 0;
	 pointer-events: none;
	 background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
	 background-repeat: no-repeat;
	 background-position: 50%;
	 transform: scale(10, 10);
	 opacity: 0;
	 transition: transform 0.5s, opacity 1s;
}
 .menu-item:active:after {
	 transform: scale(0, 0);
	 opacity: 0.2;
	 transition: 0s;
}
 .menu-item:nth-child(3) {
	 transition-duration: 180ms;
}
 .menu-item:nth-child(4) {
	 transition-duration: 180ms;
}
 .menu-item:nth-child(5) {
	 transition-duration: 180ms;
}
 .menu-item i {
	 display: inherit !important;
	 padding-top: 15px;
	 width: 100%;
}
 .menu-item-span {
	 font-size: 16px;
	 text-align: center;
	 width: 100%;
}
 
.menu-item {
	 transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);

}

.menu-item:nth-of-type(1) {
	 transition-duration: 190ms;
	 right: 70px;
	 
}
.menu-item:nth-of-type(3) {
	 transition-duration: 290ms;
}
.menu-item:nth-of-type(2) {
	 transition-duration: 390ms;
	 right: 10px;
}
 