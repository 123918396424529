.container {
  max-width: 900px;
  width: 100%;
  margin: auto;
}

.vax_header {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.persona {
  display: flex;
}

.quote {
  border-left: 3px solid #dfdfdf;
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;

  color: #999999;
  font-style: italic;
}

.bg {
  background-color: red;
  background-image:
    radial-gradient(
      circle at top right,
      #263841,
      #0d161c 70%
    );
}
