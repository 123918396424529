@value navyBlue from './common/css/common.module.css';

body {
  margin: 0;
  font-family: 'basic-sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  line-height: 32px;
  
}
html {
  overflow-x: hidden;
  font-family: sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'basic-sans', sans-serif;
  background-color:  #fefefe;
  color: #303A49;
}

.slick-next:before, .slick-prev:before {
  color: #445566;
  display: none
}

.slick-arrow{
  color: #445566;
  display: none !important;
}

.slick-dots {
  display: flex !important;
}

.slick-active {
  border: 2px solid #364ae7;
  border-radius: 10px;
  overflow: hidden;
}

.slick-list .slick-active {
  border: 0;
}

.slick-dots li {
  flex: 1;
  cursor: pointer;
}

ul.slick-dots {
  padding: 0;
  list-style: none;
}

b {
  color: #000;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-collapse {
  float: right;
  height: auto;
}

.navbar-toggler {
  float: right;
}

h1 {
  font-family: 'upgrade', sans-serif;
  font-weight: 500;
  color: #111825;
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

h2 {
  font-family: 'upgrade', sans-serif;
  font-weight: 500;
  color: #111825;
  font-size: 30px;
  margin-bottom: 20px;
  text-transform: uppercase;

}

mark {
  background-color: #fff;
}

b {
  background-color: #3f53d121;
  padding: 5px;
  font-weight: 500;
  
}

h4 {
    font-size: 18px;
    color: #1f3e54;
    font-weight: 600;
    position: relative;
    display: inline-block;
    align-self: flex-start;
}

#gooey-nav-svgs {
  position: absolute;
  bottom: 0;
  z-index: -1;
}
.quote {
  border-left: 2px solid #dfdfdf;
  font-style: italic;
  padding: 20px;
}
@media screen and (max-width: 991px) {
  .navbar-collapse {
    float: none;
  }

}

