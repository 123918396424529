.basic_margin {
  max-width: 900px;
  margin: 1.8rem auto;
  padding: 20px;
}

.basic_padding {
  padding: 1.8rem 20%;
}

.persona {
  display: flex;
}

.title_flex {
  margin-top: 120px
}

.quote {
  quotes: "“" "”" "‘" "’";
  font-size: 20px;
  display: flex;
  align-items: center;
  font-style: italic;
  justify-content: center;
  margin: 30px 0px;
}

.quote::before, .quote::after {
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #0074ff;
  font-size: 90px;
  margin-bottom: -40px;
  padding: 20px;
}
.quote::before {
  content: open-quote;
}
.quote::after {
  content: close-quote;
}

.source {
  display: flex;
  align-self: flex-end;
}

.quote_container {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.quick_info h5 {
  font-family: 'Roboto Slab';
  font-weight: 900;
  color: #213e7a;
  text-transform: uppercase;
}

.title_flex h5{
  color: #0064e5;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  margin-bottom: 20px;
}

.table_stats {
  width: 600px;
}

.final_design {
  background: #F0F2F6;
  
}

.box {
  border: 1px dashed #9ea9b5;
  border-radius: 20px;
  padding: 70px;
  margin: 5rem auto;
  text-align: center;
}

.box:nth-child(1) {
  margin-right: 20px;
}

.changes_title h4 {
  font-size: 1rem;
  color: #0074ff !important;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Roboto Slab';
  letter-spacing: 2px
}

.changes_title h3 {
  font-size: 1.5rem;
}

.table {
  width: 100%;
  box-shadow: 6px 5px 20px 0px rgb(207, 215, 230);
  border-radius: 5px;
  padding: 30px;
  overflow-x: scroll;
}

.table table {
  width: 900px;
}

.table td , .table th{
  padding: 20px;
  
}

.table th {
  text-align: center
}

.table td {
  width: 25%;
  border-top: 1px solid #dbe3ee;
}

.table tr td:nth-child(1) {
  text-align: center;
}

.infos {
  background: #EBF3FF;
  width: 100%;
  padding: 40px;
  display: flex;
  border-radius: 20px;
}

.infos div {
  margin: 20px;
  flex: 1;
}

.infos h1 {
  text-align: center;
  font-size: 40px;
  color: #0074ff;
  font-weight: 700;
}

.process {
  display: block;
  margin: 0px auto 80px;
}

.projects {
  /* max-width: 1000px;
  width: 80%; */
  padding: 1.8rem 20%;
  margin: auto;
  color: #515767;
}

.section {
  margin: 80px 0px;
}
.tools {
  background-color:  #67718011;
}

.product_mock {
  background-color: #ebedf1;
  min-height: 600px;
  height: auto;
  max-width: 1000px;
  margin: auto;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.product_mock img {
  max-width: 500px;
}

.project {
  margin-top: 200px;
  font-family: 'basic-sans', sans-serif;
  color: #515767;
  line-height: 1.7;

}

.hp1 {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 200px;
}

.hp2 {
  position: absolute;
  top: 10%;
  left: 0;
  max-width: 200px;
}


.project h1 {
  font-size: 3rem;
  font-weight: 400;
  margin: 0;
  text-transform: none;
  font-family: 'basic-sans', sans-serif;
}

.project h6 {
  background-color: #3749E6;
  padding: 5px;
  color: #fff;
  width: auto;
  display: inline-block;
  font-size: 14px;
  line-height: 1rem;

}


.basic_margin h1, .basic_padding h1 {
  font-size: 2.5rem !important;
  margin-bottom: 20px;
}

.project h5 {
  font-weight: 500;
  margin: 0;
  font-size: 14px;
  color: #898DA3;
}


.tools {
  text-align: left;
  margin: 3rem auto;
}

.info {
  padding: 40px 10px;
  background-color: #F0F2F6;
  width: 100%;
}

.button {
  display: block;
    background-color: #364ae6;
    margin: auto;
    color: #fff !important;
    padding: 5px 15px;
    width: fit-content;
    border-radius: 5px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.button:hover {
  color: #fff;
  text-decoration: none;
  background-color: #2132b8;
}

.tool_icon {
  width: 25px;
  margin-right: 10px;
}

.info h5  {
  color: #1D222F;
  font-weight: 700;
  font-size: 1.1rem !important;
  letter-spacing: normal;
  margin-bottom: 10px;
}

.tools div {
  flex: 1;
  
}

.tools h2, .prob_sol h2 {
    font-size: 25px;
    line-height: 1.5;
    color: #293846;
    margin-bottom: 10px;
    font-family: 'Sofia Pro';
    font-weight: 600;
    text-transform: none;
}
.prob_sol {
  display: flex;
}

.prob_sol div {
  flex: 1;
}

.tools {
  display: flex;
}
.tools div {
  flex: 1;
}

.prob_sol div:nth-child(1) {
  padding-right: 20px;
}

.quick_info div {
  flex: 1;
  text-align: center;

}

.quick_info {
  margin: auto;
  display: flex;
  flex-direction: row;

}

.quick_info h1 {
  margin: 0;
    display: inline-block;
    font-size: 1rem !important;
    font-weight: 600;
    text-transform: uppercase;
    color: #1D222F;
    margin-bottom: 20px;
    border-bottom: 2px solid #364ae6;
    padding-bottom: 10px;
}

.quick_info h5 {
  font-family: 'sofia-pro';
  font-weight: 700;
  color: rgb(49, 57, 77);
  font-size: 16px;
  border-bottom: 1px solid orange;
  padding-bottom: 10px;
  margin: auto;
  width: fit-content;
}


.tools span::after {
  content: ', '
}

@media screen and (max-width: 774px) {
  .tools {
    flex-direction: column;
  }

}

@media screen and (max-width: 587px) {
  .quick_info {
    flex-direction: column;
  }
}
@media screen and (max-width: 460px) {
  .prob_sol {
    flex-direction: column;
  }
  .prob_sol div:nth-child(1) {
    padding: 0;
  }
  .prob_sol div:nth-child(2) {
    margin-top: 3rem;
  }
}


.inner {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 700px;
  height: 100%;
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}

.process {
  width: 100%;
  text-align: center;
}

.box .title_flex {
  margin: 0px !important;
}

@media screen and (max-width: 1020px) {
  .product_mock {
    margin: 0px 20px;
  }
}
@media screen and (max-width: 883px) {
  .box_cont {
    flex-direction: column
  }
}

@media screen and (max-width: 774px) {
  .projects {
    padding: 20px;
  }

  .basic_margin {
    margin: 1.8rem 5%;
  }
  .basic_padding {
    padding: 1.8rem 10%;
  }

  .inner {
    margin: 1.8rem 10%;
  }
}


@media screen and (max-width: 789px) {
  .infos {
    flex-direction: column;
    text-align: center;
  }
}
@media screen and (max-width: 616px) {
  .hp1, .hp2 {
    z-index: -1;
  }
}
@media screen and (max-width: 610px) {
  .product_mock_inner {
    flex-direction: column
  }

  .product_mock_inner div:nth-child(1){
    text-align: center;
    max-width: 300px;
  }
  .product_mock_inner div:nth-child(1){
    order: 1
  }
  .process img {
    width: 100% !important;
  }
}
@media screen and (max-width: 580px) {
  .inner {
    flex-direction: column;
  }
  .hp1, .hp2 {
    width: 150px;
  }
}

@media screen and (max-width: 410px) {

  .quick_info {
    flex-direction: column;
    margin: 20px;
  }
  .quick_info div {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .process img {
    width: 100% !important;
  }
    .hp1, .hp2 {
    width: 100px;
  }
}