.project_container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  margin-top: 100px;
}

.item {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #44556622;
  margin: 20px 0px;
  transition: all 0.3s ease-in-out;

}

.item:hover {
  box-shadow: 5px 17px 40px -10px #bcbfce;
  transform: translateY(-15px);
  text-decoration: none;
}

.item_inner img {
  width: 100%;
}

.avatar img {
  width: 50px;
  border-radius: 100px;
}

.item_inner {
  flex: 1;
  background-size: cover;
  background-position: center;
}

.info {
  padding: 40px;
}

.title {
  font-size: 25px;
  line-height: 1.5;
  color: #293846;
  font-weight: 500;
  margin-bottom: 10px;
}

.date {
  margin-top: 20px;
  color: #9fa8b0;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
}
.subtitle {
    color: #657480;
    line-height: 1.5;

}

@media screen and (max-width: 540px) {
  .item {
    flex-direction: column;
  }

  .item_inner {
    flex: 0 0 300px
  }
}


