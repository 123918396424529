.d_flex {display: flex; }
.flex_one {flex: 1}
.text_center {text-align: center;}
.text_left {text-align: left;}
.justify_center {justify-content: center;}
.flex_center {align-items: center;}
.flex_end {align-items: end;}
.flex_column {flex-direction: column;}
.flex_wrap {flex-wrap: wrap;}
.button {
  margin: auto;
  background: #394EDD;
  color: #fff;
  border-radius: 5px;
  padding: 10px 40px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.button:hover {
  color: #FFF;
  text-decoration: none;
  background: #2233A7
}

.prototype {
  border: 5px solid #000;
  border-radius: 30px;
  margin: auto;
}

.float_left {float: left;}
.float_right {float: right;}