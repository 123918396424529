.container {
  max-width: 900px;
  width: 100%;
  margin: auto;
}

.vax_header {
  background: url('/static/images/hifi.png');
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.persona {
  display: flex;
}

.quote {
  border-left: 3px solid #dfdfdf;
  margin: 10px;
  margin-left: 20px;
  padding: 10px 20px;

  color: #999999;
  font-style: italic;
}

.button {
  padding: 10px 15px;
  margin: 2rem auto !important;
  color: #fff;
  background: #0074FF;
  border-radius: 4px;
  font-size: 14px;
  display: inline-flex;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
}

.btn_cont {
  width: 100%;
  text-align: center;
}

a.button:hover {
  text-decoration: none;
  color: #fff;
  background-color: #0074ffa9;
}
