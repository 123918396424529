.container {
  max-width: 1100px;
  width: 100%;
  margin: 5rem auto;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  flex-direction: column;
}
.about_container {
  display: flex;
  padding: 40px;
  padding-bottom:0px ;
}

.about_container h1 {
  margin-bottom: 10px;
}

.picture {
  max-width: 200px;
  width: 50vw;
  border-radius: 100px;
  margin-right: 50px;
  margin-bottom: 40px;
}

.container a {
  font-weight: 600;
}

.about_container h5 {
  text-transform: none;
  color: #303A49;
  letter-spacing: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 30px;
}

.about_container h5 b {
  text-transform: none;
  color: #3a4fdd;
  letter-spacing: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 30px;
}

.fun_img {
  width: 20%;
  border-radius: 10px;
  margin: 10px;

}

.achi {
  border-left: 1px solid #627180;
  padding-left: 10px;
  margin: 10px 0px;
}

.achi p {
  margin: 0px;
  font-size: 14px;
  color: #626b74;
}

.fun_facts div {
  flex: 1;
}

.fun_facts {
  margin: 1.8rem 25%;
  display: flex;

}

.achievement_cont {
  display: flex;
  flex-direction: column;
}

.about_container i {
  margin: 10px;
}

.hp1 {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 200px;
  z-index: -1;
}

.hp2 {
  position: absolute;
  top: 10%;
  left: 0;
  max-width: 200px;
  z-index: -1;
}

@media screen and (max-width: 1271px) {
  .hp1, .hp2 {
    max-width: 150px;
  }
}
@media screen and (max-width: 1071px) {
  .hp1, .hp2 {
    max-width: 100px;
  }
}


@media screen and (max-width: 980px) {
  .about_container {
    flex-direction: column;
  }

  .about_container h4 {
    display: none;
  }

  .about_container:nth-child(1), .about_container:nth-child(2) {
    text-align: center;
  }

  .achievement_cont {
    display: none
  }

  .picture {
    margin: 0;
  }
}

@media screen and (max-width: 518px) {
  .about_container {
    padding: 10px;
  }

  .picture {
    width: 75%;
  }
}
