
.walden_container {
  width: 100%;
  margin: auto;
  margin-top: 40px;
}

.container {
  display: flex;
}

.flex_item {
  flex: 1;
  flex-wrap: wrap;
}
